import React, { useState, useEffect } from "react";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  getRegisterSelector,
  registerUser,
} from "../../../services/slices/auth/register";
import PasswordStrengthBar from "react-password-strength-bar";
import { ReactComponent as Check } from "../../../assets/img/password-check-active.svg";
import { ReactComponent as Uncheck } from "../../../assets/img/password-check-inactive.svg";

export default function Third({
  personalDetails,
  phone,
  //investmentDetails,
}) {
  const dispatch = useDispatch();
  // use the hook and selector
  const { loading } = useSelector(getRegisterSelector);
  const [inputValues, setInputValues] = useState({
    password: "",
    passwordTwo: "",
  });
  const [isMin, setIsMin] = useState(false);
  const [isAlphaNum, setIsAlphNum] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  function signUp() {
    const plus = "+";
    const phoneNumber = plus.concat(phone);
    if (personalDetails.referral === "") {
      const data = {
        email: personalDetails.email,
        password: inputValues.password,
        firstname: personalDetails.first_name,
        lastname: personalDetails.last_name,
        address: personalDetails.address,
        phone: phoneNumber,
        /*linkedIn: investmentDetails.linkedln,
        risk: investmentDetails.risk,
        interest: investmentDetails.interest,
        investmentsize: investmentDetails.size,
        period: investmentDetails.period,*/
      };
      dispatch(registerUser(data));
    } else {
      const data = {
        email: personalDetails.email,
        password: inputValues.password,
        firstname: personalDetails.first_name,
        lastname: personalDetails.last_name,
        address: personalDetails.address,
        phone: phoneNumber,
        /*linkedIn: investmentDetails.linkedln,
        risk: investmentDetails.risk,
        interest: investmentDetails.interest,
        investmentsize: investmentDetails.size,
        period: investmentDetails.period,*/
        referral_code: personalDetails.referral,
      };
      dispatch(registerUser(data));
    }
  }

  useEffect(() => {
    if (
      inputValues.password !== "" &&
      inputValues.passwordTwo !== "" &&
      inputValues.password === inputValues.passwordTwo
    ) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
    }
  }, [inputValues.password, inputValues.passwordTwo]);

  useEffect(() => {
    if (
      isMin &&
      isAlphaNum &&
      isMatch &&
      isLowerCase &&
      isSpecialCharacter &&
      isUpperCase
    ) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [
    isMin,
    isAlphaNum,
    isMatch,
    isLowerCase,
    isSpecialCharacter,
    isUpperCase,
  ]);

  useEffect(() => {
    const pass = inputValues.password;
    const reg = /[!@#$%^&*(),.?":{}|<>]/g;
    const test = reg.test(pass);
    if (test) {
      setIsSpecialCharacter(true);
    } else {
      setIsSpecialCharacter(false);
    }
  }, [inputValues.password]);

  useEffect(() => {
    const pass = inputValues.password;
    const reg = /[0-9]/g;
    const test = reg.test(pass);
    if (test) {
      setIsAlphNum(true);
    } else {
      setIsAlphNum(false);
    }
  }, [inputValues.password]);

  useEffect(() => {
    const pass = inputValues.password;
    if (pass.length < 6) {
      setIsMin(false);
    } else {
      setIsMin(true);
    }
  }, [inputValues.password]);

  useEffect(() => {
    const pass = inputValues.password;
    const uppercaseReg = /[A-Z]/g;
    const uppercaseTest = uppercaseReg.test(pass);
    if (uppercaseTest) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }
  }, [inputValues.password]);

  useEffect(() => {
    const pass = inputValues.password;
    const lowercaseReg = /[a-z]/g;
    const lowercaseTest = lowercaseReg.test(pass);
    if (lowercaseTest) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }
  }, [inputValues.password]);

  return (
    <div className="first-signup">
      <h1 className="mb-4">
        Set your login <span className="orange">password</span>
      </h1>
      <div>
        <h2 className="mb-1">Enter Password</h2>
        <InputField
          type="password"
          value={inputValues.password}
          onChange={handleChangeInput}
          name="password"
        />
      </div>

      <div>
        <h2 className="mb-1">Confirm Password</h2>
        <InputField
          type="password"
          value={inputValues.passwordTwo}
          onChange={handleChangeInput}
          name="passwordTwo"
        />
      </div>
      <PasswordStrengthBar
        className="password__checker"
        password={inputValues.password}
      />
      {inputValues.password.length > 0 && (
        <ul className="card-check color-black no-card--check">
          <li className={`flex password__check--text ${isMin ? "isTrue" : ""}`}>
            <span className="me-2">{isMin ? <Check /> : <Uncheck />}</span>At
            least 6 characters
          </li>
          <li className={`flex password__check--text ${isMin ? "isTrue" : ""}`}>
            <span className="me-2">
              {isUpperCase ? <Check /> : <Uncheck />}
            </span>
            A capital / uppercase letter
          </li>
          <li className={`flex password__check--text ${isMin ? "isTrue" : ""}`}>
            <span className="me-2">
              {isLowerCase ? <Check /> : <Uncheck />}
            </span>
            A lowercase letter
          </li>
          <li
            className={`flex password__check--text ${
              isAlphaNum ? "isTrue" : ""
            }`}
          >
            <span className="me-2">{isAlphaNum ? <Check /> : <Uncheck />}</span>
            Contains at least one number
          </li>
          <li
            className={`flex password__check--text ${isMatch ? "isTrue" : ""}`}
          >
            <span className="me-2">{isMatch ? <Check /> : <Uncheck />}</span>
            Match with your confirm password
          </li>
          <li
            className={`flex password__check--text ${
              isAlphaNum ? "isTrue" : ""
            }`}
          >
            <span className="me-2">
              {isSpecialCharacter ? <Check /> : <Uncheck />}
            </span>
            A special character e.g, %, @, $
          </li>
        </ul>
      )}

      <Button
        type="button"
        onClick={signUp}
        style={{ width: "100%", borderRadius: "10px" }}
        disabled={
          !(inputValues.password && inputValues.passwordTwo) || !isAuthorized
        }
      >
        {loading ? "Loading" : "Proceed"}
      </Button>
    </div>
  );
}
