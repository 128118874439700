import React, { useState } from "react";
import logo from "../../../assets/img/logo.png";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import makeAPICall from "../../../utils/apiUtils";
import { useParams } from "react-router-dom";
import InputField from "../../../components/InputField";

export default function ResetPassword() {
  const { id, token } = useParams();
  console.log(id, token);
  const [inputValues, setInputValues] = useState({
    password: "",
    repeat_password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const handleSubmit = () => {
    if (inputValues.password === inputValues.repeat_password) {
      setIsLoading(true);
      const data = {
        password: inputValues.password,
        token: token,
        uidb64: id,
      };
      return makeAPICall({
        path: "/auth/password-reset-complete",
        method: "PATCH",
        payload: data,
      })
        .then((res) => {
          setIsLoading(false);
          toast("Password reset successful, go to login page", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          toast(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast("Password don't match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <section className="step-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row remove-p bg-white">
              <div className="col-md-4">
                <div className="step-div p-5">
                  <img src={logo} alt="Yield room Logo" className="logo" />
                  <div>
                    <h1 className="login-welcome mt-5">Welcome Back</h1>
                    <p className="login-welcome-details">Change password</p>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="content-div bg-white p-5">
                  <div className="first-signup">
                    <h1 className="mb-4">Enter your new password</h1>
                    <div>
                      <h2 className="mb-1">Password</h2>
                      <InputField
                        type="password"
                        value={inputValues.password}
                        onChange={handleChangeInput}
                        name="password"
                        placeholder="password"
                      />
                    </div>
                    <div>
                      <h2 className="mb-1">Repeat Password</h2>
                      <InputField
                        type="password"
                        value={inputValues.repeat_password}
                        onChange={handleChangeInput}
                        name="repeat_password"
                      />
                    </div>

                    <Button
                      type="button"
                      onClick={handleSubmit}
                      disabled={!(inputValues.password && inputValues.email)}
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        marginTop: "20px",
                      }}
                    >
                      {isLoading ? "Loading..." : "Login"}
                    </Button>
                    <div className="text-center mt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            <Link to="/signup">
                              <span className="orange">Sign up</span>
                            </Link>{" "}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p>
                            <Link to="/signup/issuer">
                              <span className="orange">Become an issuer</span>
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
