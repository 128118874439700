import React from "react";
import { Layout, Menu, Dropdown, Avatar, Badge } from "antd";
import { ReactComponent as InvestmentIcon } from "../../assets/img/Investments.svg";
// import { ReactComponent as SearchIcon } from "../../assets/img/Search.svg";
import { ReactComponent as SettingsIcon } from "../../assets/img/Setting.svg";
import { ReactComponent as DashboardIcon } from "../../assets/img/Category.svg";
import logo from "../../assets/img/logo.png";
import notification from "../../assets/img/Notification.svg";
import dropDown from "../../assets/img/arrow-down.png";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils/authUtils";
import "./back.css";
import randomColor from "randomcolor";
import { useSelector } from "react-redux";
import { getLoginSelector } from "../../services/slices/auth/login";

const { Header, Content, Sider } = Layout;

export default function BackBone({ children, title }) {
  const { user } = useSelector(getLoginSelector);
  const navigate = useNavigate();

  const items = [{ label: "Logout", key: "logout", onClick: logout }];

  const menu = <Menu className="p-4" items={items} />;

  return (
    <div className="nav-area">
      <Layout style={{ height: "100vh" }}>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          // onBreakpoint={(broken) => {
          //   console.log(broken);
          // }}
          // onCollapse={(collapsed, type) => {
          //   console.log(collapsed, type);
          // }}
        >
          <div className="logo">
            <img src={logo} className="logo" alt="logo" />
          </div>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
            onClick={({ key }) => {
              navigate(key);
            }}
            items={[
              {
                label: "Dashboard",
                key: "/dashboard",
                icon: <DashboardIcon />,
              },
              {
                label: "Investments",
                key: "/investments",
                icon: <InvestmentIcon />,
              },
              {
                label: "All Investments",
                key: "/all",
                icon: <InvestmentIcon />,
              },
              { label: "Settings", key: "/settings", icon: <SettingsIcon /> },
            ]}
          />
        </Sider>
        <Layout>
          <Header
            className="site-layout-sub-header-background"
            style={{
              padding: "10px 24px",
            }}
          >
            <div className="d-md-flex align-items-center justify-content-between header-nav">
              <h1 className="header-title">{title}</h1>
              {/* <Input
                size="large"
                placeholder="Search by dashboard"
                prefix={<SearchIcon />}
              /> */}
              {/* <Button type="button" onClick={onClick}>
                Upload Investment
              </Button> */}

              <div className="dropdownSection">
                <div className="d-flex align-items-center">
                  <Badge count={0} showZero>
                    <img
                      src={notification}
                      alt="notification icon"
                      className="notify-icon"
                    />
                  </Badge>
                  <Dropdown
                    overlay={menu}
                    placement="bottom"
                    arrow={{ pointAtCenter: true }}
                  >
                    <div className="d-flex justify-content-between">
                      <Avatar
                        size="large"
                        style={{
                          backgroundColor: randomColor(),
                          verticalAlign: "middle",
                          border: "2px solid #FCB017",
                        }}
                      >
                        <span>{user.username.charAt(0)}</span>
                      </Avatar>
                      <div className="d-flex align-items-center justify-content-between header-nav">
                        <div>
                          <h1>{user?.firstname}</h1>
                          <p>Investor</p>
                        </div>
                        <img
                          src={dropDown}
                          alt="dropdown icon"
                          className="tick-arr"
                        />
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Header>

          <Content
            style={{
              margin: "24px 16px 0",
              minHeight: "calc(100vh - 114px)",
              overflow: "auto",
            }}
          >
            <div className="site-layout-background" style={{ minHeight: 670 }}>
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
