import React, { useState, useEffect } from "react";
import { Steps } from "antd";
import First from "./First";
//import Second from "./Second";
import Third from "./Third";
// import Fourth from "./Fourth";
import "./step.css";
import logo from "../../../assets/img/logo.png";
import congrats from "../../../assets/img/congrtz 1.png";
import { useSelector } from "react-redux";
import { getRegisterSelector } from "../../../services/slices/auth/register";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const navigate = useNavigate();
  const { register } = useSelector(getRegisterSelector);
  const [current, setCurrent] = useState(0);
  const [inputDetails, setInputDetails] = useState();
  const [phoneState, setPhoneState] = useState("");
  //const [investmentDetails, setInvestmentDetails] = useState();
  const [lastView, setLastView] = useState(false);

  const { Step } = Steps;

  const steps = [
    {
      title: "Personal Information",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      content: <First onClick={nextFirst} />,
    },
    /*
    {
      title: "Investment Information",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      content: <Second onClick={nextSecond} />,
    },
    */
    {
      title: "Create Password",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      content: (
        <Third
          personalDetails={inputDetails}
          phone={phoneState}
          //investmentDetails={investmentDetails}
        />
      ),
    },
  ];

  function nextFirst(details, phone) {
    setPhoneState(phone);
    setInputDetails(details);
    setCurrent(current + 1);
  }

  /*
  function nextSecond(linkedIn, size, period, risk, interest) {
    setInvestmentDetails({
      linkedin: linkedIn || "",
      size: size,
      period: period,
      risk: risk,
      interest: interest,
    });
    setCurrent(current + 1);
  }
  */

  //   function prev() {
  //     setCurrent(current - 1);
  //   }

  useEffect(() => {
    if (
      register &&
      Object.keys(register).length === 0 &&
      Object.getPrototypeOf(register) === Object.prototype
    ) {
      setLastView(false);
    } else {
      setLastView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const gotoLogin = () => {
    navigate("/");
    window.localStorage.removeItem("persist:counter");
  };

  return (
    <section className="step-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            {lastView ? (
              <div className="row bg-white rounded-3">
                <div className="col-md-12">
                  <div className="bg-white p-5">
                    <div className="text-center">
                      <img
                        src={logo}
                        alt="Yield room Logo"
                        className="logo mb-3"
                      />
                      <br />
                      <img
                        src={congrats}
                        alt="Yield room Logo"
                        className="mb-3"
                        style={{ width: "200px" }}
                      />
                      <h1 className="congrats mb-3">Congratulations </h1>
                      <p className="congrats-details mb-5">
                        Your account will be approved within 24 working hours,
                        please look up via mail for our response
                      </p>
                      <Button type="button" onClick={gotoLogin}>
                        Proceed to Login
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row remove-p bg-white">
                <div className="col-md-4">
                  <div className="step-div p-5">
                    <img src={logo} alt="Yield room Logo" className="logo" />
                    <Steps
                      progressDot
                      direction="vertical"
                      current={current}
                      style={{ alignSelf: "flex-start" }}
                    >
                      {steps.map((item) => (
                        <Step
                          key={item.title}
                          title={item.title}
                          description={item.description}
                        />
                      ))}
                    </Steps>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="content-div bg-white p-5">
                    <div>{steps[current].content}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
