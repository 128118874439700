import React from "react";
import logo from "../../assets/img/logo.png";
import "./footer.css";

function Footer() {
  return (
    <div>
      <footer className="footer-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <img src={logo} alt="Kippa Logo" className="home-logo" />
              <p>Investment for Everyone</p>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <h2 className="mb-3">Company</h2>
              <h3 className="mb-3">About</h3>
              <h3 className="mb-3">Investment</h3>
              <h3 className="mb-3">Resources</h3>
            </div>
            <div className="col-md-2">
              <h2 className="mb-3">Quick Link</h2>
              <h3 className="mb-3">FAQs</h3>
              <h3 className="mb-3">Blog</h3>
              <h3 className="mb-3">Privacy Policy</h3>
              <h3 className="mb-3">Terms & Conditions</h3>
            </div>
            <div className="col-md-3">
              <h2 className="mb-3">Address</h2>
              <h3 className="mb-4">
                12 Wildred Road, Bourdilion,
                <br /> Ikoyi, Lagos. Nigeria.
              </h3>
              <h3 className="mb-4">+234 7012345678</h3>
              <h3 className="mb-4">support@yieldroom.com</h3>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-section py-3">
        <h1>Copyright ©Yieldroom 2022. All right reserved</h1>
      </div>
    </div>
  );
}

export default Footer;
