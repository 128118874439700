import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import "./home.css";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
// import Select from "../../components/Select";
import investmentPlant from "../../assets/img/Frame 238980.png";
import briefcase from "../../assets/img/Frame 43713.png";
import roi from "../../assets/img/Frame 43714.png";
import happy from "../../assets/img/happy.png";
import house from "../../assets/img/house1.png";
import heart from "../../assets/img/Vector.png";
import star from "../../assets/img/Star.png";
import rightArrow from "../../assets/img/right-arrow.png";
import trust from "../../assets/img/trust.png";
import account from "../../assets/img/accountability.png";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "../../utils/helperFunctions";
import makeAPICallForm from "../../utils/apiUtilsForm";
import { message } from "antd";

import {
  getCurrentInvestmentSelector,
  GetInvestmentUser,
} from "../../services/slices/getInvestment";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

// const statusOptions = [
//   { value: "yes", text: "Yes" },
//   { value: "no", text: "No" },
//   { value: "investment", text: "Investment Type" },
// ];

// const loactionOptions = [
//   { value: "yes", text: "Yes" },
//   { value: "no", text: "No" },
//   { value: "location", text: "Location" },
// ];

const baseURL = process.env.REACT_APP_API_ENDPOINT;

export default function Home() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // use the hook and selector
  const { loading, investment } = useSelector(getCurrentInvestmentSelector);
  // const [status, setStatus] = useState("investment");
  // const [location, setLocation] = useState("location");
  const [price, setPrice] = useState();
  const [loadingNow, setLoadingNow] = useState(false);

  // const handleChange = (e) => {
  //   setStatus(e.target.value);
  // };

  // const handleChangeLocation = (e) => {
  //   setLocation(e.target.value);
  // };

  useEffect(() => {
    dispatch(GetInvestmentUser({}));
  }, [dispatch]);

  // console.log(investment, "test");

  const toSingleInvestment = (item) => {
    navigate(`/home/${item?.slug}`, { state: item });
  };

  function handleSearch() {
    setLoadingNow(true);
    return makeAPICallForm({
      path: `/investment/investment/?s=${price}`,
      method: "GET",
    })
      .then((res) => {
        console.log(res);
        navigate('/search', { state: {res, price} });
        setLoadingNow(false);
        setPrice("");
      })
      .catch((err) => {
        message.error(err.message, 5);
        setLoadingNow(false);
      });
  }
  return (
    <div>
      <Outlet />
      {pathname === "/home" && (
        <div>
          <NavBar />
          <section className="hero-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="hero-title">
                    Invest, Make Returns & Build Wealth
                  </h1>
                  <p className="hero-sub-title">
                    Get high ROI on short & long term investments
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="search-section pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="filter-card">
                    <div className="row">
                      {/* <div className="col-md-4">
                        <Select
                          name="status"
                          defaultValue={status}
                          onChange={handleChange}
                          options={statusOptions}
                        />
                      </div>
                      <div className="col-md-3">
                        <Select
                          name="location"
                          defaultValue={location}
                          onChange={handleChangeLocation}
                          options={loactionOptions}
                        />
                      </div> */}
                      <div className="col-md-9">
                        <InputField
                          type="text"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          name="price"
                          placeholder="Search for an investment"
                        />
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="button"
                          onClick={handleSearch}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            height: "45px",
                          }}
                          disabled={!price}
                        >
                          {loadingNow ? "Searching..." : "Search"}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <p>
                        At Yieldroom Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel ut habitant odio at ut sodales quis
                        venenatis. Habitant porttitor neque, ultrices posuere
                        atama odio. Nisl, ignlisruj pellentesque aliquam, augue
                        et. Consectetur nisl tellus lectus nibh ac ultricies
                        egestas at elit. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel ut habitant odior at ut sodales
                        quis venenatis. Habitant porttitor neque, at ultrices
                        posuere at odio. Nisl, pellentesque aliquam, augue et.
                        Consectetur nisl tellus lectus nibh ac ultricies sidnc
                        egestas elit. Lorem atodi ipsum dolor sit amet,
                        consectetur dolor sit adipiscing elit. Vel ut habitant
                        odio at ut sodales quis venenatis. Habitant porttitor
                        neque, atodi{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="facts-section py-4">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="d-flex align-items-center">
                        <img src={investmentPlant} alt="investment" />
                        <div>
                          <h1>1500+</h1>
                          <p>Investments Ready</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex align-items-center">
                        <img src={briefcase} alt="investment" />
                        <div>
                          <h1>₦ 10M</h1>
                          <p>Already Invested</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex align-items-center">
                        <img src={roi} alt="investment" />
                        <div>
                          <h1>30%</h1>
                          <p>Avearge Annual returns</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex align-items-center">
                        <img src={happy} alt="investment" />
                        <div>
                          <h1>500+</h1>
                          <p>Happy Customers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="investment-needs py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="text-center mb-4">
                        The right values to satisfy your investment needs{" "}
                      </h1>
                      <p className="mb-5">
                        At Yieldroom Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel ut habitant odio at ut sodales quis
                        venenatis. Habitant porttitor neque, ultrices posuere
                        atama odio. Nisl, ignlisruj pellentesque aliquam, augue
                        et. Consectetur nisl tellus lectus nibh ac ultricies
                        egestas at{" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 border-end">
                      <h2 className="mb-4">Investing Essentials</h2>
                      <h3 className="mb-3">
                        At Yieldroom Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel ut habitant odio at ut sodales quis
                      </h3>
                    </div>
                    <div className="col-md-4 border-end">
                      <h2 className="mb-4">Portfolio Management</h2>
                      <h3 className="mb-3">
                        At Yieldroom Lorem ipsum dolor sit iugv amet,
                        consectetur adipiscing elit. Vel ut habitant odio at ut
                        sodales quis auquis
                      </h3>
                    </div>
                    <div className="col-md-4">
                      <h2 className="mb-4">Fundamental Analysis</h2>
                      <h3 className="mb-3">
                        At Yieldroom Lorem ipsum dolor sit iugv amet,
                        consectetur adipiscing elit. Vel ut habitant odio at ut
                        sodales quis auquis
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="listings py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="mb-3">Our Listed investment Products</h1>
                      <p className="mb-4">
                        At Yieldroom Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel ut habitant odio at ut sodales quis
                        venenatis. Habitant porttitor neque, ult odio. Nisl,
                        ignlisruj pellentesque aliquam, augue et. Consectetur
                        nisl tellus lectus nibh ac ultricies egestas elit. Lorem
                        ipsum dolor sit amet, consectt ut habitant odior at ut
                        sodales quis venenatis. Habitant porttitor neque,
                        ultrices posuere at odio. Nisl, pellentesque aliquam,
                        augue et. Consectetur
                      </p>
                      <ul
                        className="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-all-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-all"
                            type="button"
                            role="tab"
                            aria-controls="pills-all"
                            aria-selected="true"
                          >
                            All
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-estate-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-estate"
                            type="button"
                            role="tab"
                            aria-controls="pills-estate"
                            aria-selected="false"
                          >
                            Real Estate
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-oil-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-oil"
                            type="button"
                            role="tab"
                            aria-controls="pills-oil"
                            aria-selected="false"
                          >
                            Oil & Gas
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-trade-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-trade"
                            type="button"
                            role="tab"
                            aria-controls="pills-trade"
                            aria-selected="false"
                          >
                            Trade
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-tech-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-tech"
                            type="button"
                            role="tab"
                            aria-controls="pills-tech"
                            aria-selected="false"
                          >
                            Emerging Tech
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-financial-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-financial"
                            type="button"
                            role="tab"
                            aria-controls="pills-financial"
                            aria-selected="false"
                          >
                            Financial Services
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pill-creative-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-creative"
                            type="button"
                            role="tab"
                            aria-controls="pills-creative"
                            aria-selected="false"
                          >
                            Creative Arts
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-all"
                          role="tabpanel"
                          aria-labelledby="pills-all-tab"
                        >
                          {loading ? (
                            "Loading..."
                          ) : (
                            <div>
                              <div className="row mb-3 list-back">
                                {investment?.results
                                  ?.slice(0, 6)
                                  ?.map((item, index) => (
                                    <div className="col-md-4" key={index}>
                                      <div className="listing-card">
                                        <div className="img-listing">
                                          <img
                                            src={
                                              item?.image.length > 0
                                                ? `${baseURL}${item?.image[0].gallery_url}`
                                                : house
                                            }
                                            alt="listing"
                                          />
                                        </div>
                                        <div className="p-4">
                                          <div className="listing-details p-4">
                                            <div className="d-flex align-items-center justify-content-around">
                                              <div>&nbsp;</div>
                                              <div className="d-flex">
                                                <h2
                                                  className={
                                                    "open-listing mb-3 " +
                                                    (item?.is_closed
                                                      ? "orange"
                                                      : "green")
                                                  }
                                                >
                                                  {item?.is_closed
                                                    ? "Closed Investment"
                                                    : "Open Investment"}
                                                </h2>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <img
                                                  src={star}
                                                  className="rating-img"
                                                  alt="star"
                                                />
                                                <h2 className="rating">4.5</h2>
                                              </div>
                                            </div>
                                            <h2 className="listing-name my-3">
                                              {item?.room?.name} -{" "}
                                              <span>{item?.name}</span>
                                            </h2>
                                            <div className="align-items-center justify-content-around">
                                              <div className="row mb-3">
                                                <div className="col-md-6 align-items-left">
                                                  <h4 className="listing-properties">
                                                    Start
                                                  </h4>
                                                  <small>
                                                    {formatDate(
                                                      item?.start_date
                                                    )}
                                                  </small>
                                                </div>
                                                <div className="col-md-6 align-items-right">
                                                  <h4 className="listing-properties">
                                                    End
                                                  </h4>
                                                  <small>
                                                    {formatDate(item?.end_date)}
                                                  </small>
                                                </div>
                                              </div>
                                            </div>
                                            <h2 className="estimate my-4">
                                              <span>Status</span>
                                            </h2>
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <h2 className="tenor mb-2">
                                                  Title
                                                </h2>
                                                <h2
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                  className="range"
                                                >
                                                  {item?.title_status}
                                                </h2>
                                              </div>
                                              <div>
                                                <h2 className="tenor mb-2">
                                                  Construction
                                                </h2>
                                                <h2
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                  className="range text-center"
                                                >
                                                  {item?.construction_status}
                                                </h2>
                                              </div>
                                              <div>
                                                <h2 className="tenor mb-2">
                                                  Project
                                                </h2>
                                                <h2
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                  className="range text-end"
                                                >
                                                  {item?.project_status}
                                                </h2>
                                              </div>
                                            </div>
                                            <div className="text-center m-3">
                                              <Button
                                                type="button"
                                                style={{
                                                  background: "transparent",
                                                  color: "#ED8428",
                                                  outline: 0,
                                                  border: 0,
                                                }}
                                                onClick={() => {
                                                  toSingleInvestment(item);
                                                }}
                                              >
                                                View Investment
                                              </Button>
                                            </div>
                                          </div>
                                        </div>

                                        <img
                                          src={heart}
                                          alt="favourite"
                                          className="favourite"
                                        />
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-estate"
                          role="tabpanel"
                          aria-labelledby="pills-estate-tab"
                        >
                          Real Estate
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-oil"
                          role="tabpanel"
                          aria-labelledby="pills-oil-tab"
                        >
                          Oil & Gas
                        </div>
                      </div>
                      <div className="text-center mt-3">
                        <Button
                          type="button"
                          style={{
                            background: "transparent",
                            color: "#ED8428",
                            outline: 0,
                            border: 0,
                          }}
                          onClick={() => navigate("/all")}
                        >
                          Explore more &nbsp;{" "}
                          <img src={rightArrow} alt="btn arrow" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="benefits pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="mb-3">Why Invest with Yieldroom</h1>
                      <p className="mb-5">
                        At Yieldroom Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel ut habitant odio at ut sodales quis
                        venenatis. Habitant porttitor neque, ul odio. Nisl,
                        ignlisruj pellentesque aliquam, augue et. Consectetur
                        nisl tellus lectus nibh ac ultricies egestas elit. Lorem
                        ipsum dolor sit amet, consectt ut habitant odior at ut
                        sodales quis venenatis. Habitant porttitor neque,
                        ultrices posuere at odio. Nisl, pellentesque aliquam,
                        augue et. Consectetu
                      </p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <div className="benefit-card p-3">
                        <div className="mb-4 text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={trust} alt="trust" />
                            <h2>Build for Trust</h2>
                          </div>
                        </div>
                        <h3>
                          At Yieldroom Lorem ipsum im dolor sit jdh amet,
                          consectetur ri adipiscing elit. Vel ut habitant odio
                          at hbuh yriens ouh
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="benefit-card p-3">
                        <div className="mb-4 text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={account} alt="trust" />
                            <h2>Accountability</h2>
                          </div>
                        </div>
                        <h3>
                          At Yieldroom Lorem ipsum im dolor sit jdh amet,
                          consectetur ri adipiscing elit. Vel ut habitant odio
                          at hbuh yriens ouh
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="benefit-card p-3">
                        <div className="mb-4 text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={trust} alt="trust" />
                            <h2>Legitimate fund Raiser</h2>
                          </div>
                        </div>
                        <h3>
                          At Yieldroom Lorem ipsum im dolor sit jdh amet,
                          consectetur ri adipiscing elit. Vel ut habitant odio
                          at hbuh yriens ouh
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="benefit-card p-3">
                        <div className="mb-4 text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={account} alt="trust" />
                            <h2>Smooth Tracking</h2>
                          </div>
                        </div>
                        <h3>
                          At Yieldroom Lorem ipsum im dolor sit jdh amet,
                          consectetur ri adipiscing elit. Vel ut habitant odio
                          at hbuh yriens ouh
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="benefit-card p-3">
                        <div className="mb-4 text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={trust} alt="trust" />

                            <h2>100% Tech Driven</h2>
                          </div>
                        </div>
                        <h3>
                          At Yieldroom Lorem ipsum im dolor sit jdh amet,
                          consectetur ri adipiscing elit. Vel ut habitant odio
                          at hbuh yriens ouh
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="benefit-card p-3">
                        <div className="mb-4 text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={account} alt="trust" />
                            <h2>Investment Rating</h2>
                          </div>
                        </div>
                        <h3>
                          At Yieldroom Lorem ipsum im dolor sit jdh amet,
                          consectetur ri adipiscing elit. Vel ut habitant odio
                          at hbuh yriens ouh
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="start-investment">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="row">
                    <div className="col-md-6">
                      <h1 className="mb-2">
                        Grow your investment with Yieldroom
                      </h1>
                      <p className="mb-5">Investment for Everyone!</p>
                      <Button
                        type="button"
                        style={{
                          background: "white",
                          color: "#ED8428",
                          borderRadius: "10px",
                        }}
                      >
                        Start Investing Now &nbsp;{" "}
                        <img src={rightArrow} alt="btn arrow" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </div>
  );
}
