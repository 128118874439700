import React, { useState, useEffect } from "react";
import backbtn from "../../../assets/img/Back.svg";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { DatePicker, message } from "antd";
import placeholder from "../../../assets/img/tied.png";
//import makeAPICall from "../../../utils/apiUtils";
import { useSelector, useDispatch } from "react-redux";
import {
  getInvestmentSelector,
  InvestmentUser,
  revertAll,
} from "../../../services/slices/createIssuer";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
// import { toast } from "react-toastify";

export default function UploadInvestment({ close }) {
  const dispatch = useDispatch();
  // use the hook and selector
  const { loading, newInvestment } = useSelector(getInvestmentSelector);
  const [inputValues, setInputValues] = useState({
    investment_name: "",
    investment_video: "",
    investment_volume: "",
    investment_location: "",
    investment_description: "",
    investment_features: "",
  });

  const [image, setImage] = useState();
  const [investors, setInvestors] = useState();
  const [multipleImage, setMultipleImage] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [titleStatus, setTitleStatus] = useState();
  const [constructionStatus, setConstructionStatus] = useState();
  const [projectStatus, setProjectStatus] = useState();

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const onChangeStart = (date, dateString) => {
    // console.log(dateString);
    setStartDate(moment(dateString));
  };

  const onChangeEnd = (date, dateString) => {
    // console.log(dateString);
    setEndDate(moment(dateString));
  };

  const handleTitleStatus = (e) => {
    setTitleStatus(e.target.value);
  };
  const handleConstructionStatus = (e) => {
    setConstructionStatus(e.target.value);
  };
  const handleProjectStatus = (e) => {
    setProjectStatus(e.target.value);
  };

  useEffect(() => {
    if (
      newInvestment &&
      Object.keys(newInvestment).length === 0 &&
      Object.getPrototypeOf(newInvestment) === Object.prototype
    ) {
      console.log("yes");
    } else {
      close();
      dispatch(revertAll());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newInvestment]);

  const handleChange = (e) => {
    const uploadFile = e.target.files[0];
    if (uploadFile) {
      const fileType = uploadFile["type"];
      const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (validImageTypes.includes(fileType)) {
        setImage(uploadFile);
      } else {
        console.log("Please select an image to upload");
      }
    }
  };

  const handleChangeMultiple = (e) => {
    const mul = [...multipleImage];
    mul.push(...e.target.files);
    setMultipleImage(mul);
  };

  const handleInvestorsInput = (e) => {
    // handle validations
    const uploadInvestorFile = e.target.files[0];
    if (uploadInvestorFile.size > 1024)
      message.success(
        "You have created an order, please check the orders page to view order",
        5
      );
    else setInvestors(uploadInvestorFile);
  };

  const newInvestmentButton = () => {
    const formData = new FormData();
    formData.append("gallery", image);
    for (let i = 0; i < multipleImage.length; i++) {
      formData.append(`galleries_${i}`, multipleImage[i]);
    }
    formData.append("investors", investors);
    formData.append("name", inputValues.investment_name);
    formData.append("description", inputValues.investment_description);
    formData.append("features", inputValues.investment_features);
    formData.append("title_status", titleStatus);
    formData.append("construction_status", constructionStatus);
    formData.append("project_status", projectStatus);
    formData.append("location", inputValues.investment_location);
    formData.append("video", inputValues.investment_video);
    formData.append("volume", inputValues.investment_volume);
    formData.append("start_date", startDate.format("YYYY-MM-DD"));
    formData.append("end_date", endDate.format("YYYY-MM-DD"));
    dispatch(InvestmentUser(formData));
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-md-12">
          <div
            className="d-flex align-items-center"
            onClick={close}
            style={{ cursor: "pointer" }}
          >
            <img src={backbtn} alt="go back" style={{ marginRight: "10px" }} />
            <h1 className="back-text">Upload Investment</h1>
          </div>
        </div>
      </div>
      <div className="bg-white investment-form-fields rounded-3 p-4">
        <div className="row mt-5">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Investment Name</h2>
              <InputField
                type="text"
                value={inputValues.investment_name}
                onChange={handleChangeInput}
                name="investment_name"
                placeholder="Give the investment a name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Location of the Investment</h2>
              <InputField
                type="text"
                value={inputValues.investment_location}
                onChange={handleChangeInput}
                name="investment_location"
                placeholder="Enter investment location"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Investment Video</h2>
              <InputField
                type="text"
                value={inputValues.investment_video}
                onChange={handleChangeInput}
                name="investment_video"
                placeholder="e.g https://www.youtube.com/watch?v=c_OwSqXjkg4"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Number of units</h2>
              <InputField
                type="number"
                value={inputValues.investment_volume}
                onChange={handleChangeInput}
                name="investment_volume"
                placeholder="e.g. 216"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Description</h2>
              <TextArea
                value={inputValues.investment_description}
                onChange={handleChangeInput}
                name="investment_description"
                placeholder="Describe the investment"
              />
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Feature List
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Enter these Number of rooms, land size, Title Approval State
                    - completed, Construction Approval State - in progress,
                    Number of floors - 5, Developer - (Chimauche Njoku),
                    Architect - (Emmanuel Chilaka), QS - (Jaja Nonso), EA -
                    (Demo Usman), ME - (Sample Bukky), Registration Stratis -
                    (Completed)
                  </span>
                </div>
              </h2>
              <TextArea
                value={inputValues.investment_features}
                onChange={handleChangeInput}
                name="investment_features"
                placeholder="Add features of the investment separated with comma, eg Number of floors - 5, Number of room - 15, Developer: Sanchez Consultant"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Title Status</h2>
              <select
                onChange={handleTitleStatus}
                className="select-area"
                name="title_status "
                value={titleStatus}
              >
                <option>--Choose an option--</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Construction Status</h2>
              <select
                onChange={handleConstructionStatus}
                className="select-area"
                name="construction_status "
                value={constructionStatus}
              >
                <option>--Choose an option--</option>
                <option value="not started">Not started</option>
                <option value="in progress">In progress</option>
                <option value="completed">Completed</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Project Status</h2>
              <select
                onChange={handleProjectStatus}
                className="select-area"
                name="project_status "
                value={projectStatus}
              >
                <option>--Choose an option--</option>
                <option value="not started">Not started</option>
                <option value="in progress">In progress</option>
                <option value="completed">Completed</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div></div>
            <br />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Investment Start Date{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    When the project for which the funding is needed starts
                  </span>
                </div>
              </h2>
              <DatePicker value={startDate} onChange={onChangeStart} />
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">Investment End Date</h2>
              <DatePicker value={endDate} onChange={onChangeEnd} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">Featured Image</h2>
              <div className="img-upload p-2">
                <label htmlFor="upload-button" className="label-profile">
                  <img src={placeholder} alt="take" />
                  {image ? image.name : "Upload Image"}
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">Other Images</h2>
              <div className="img-upload p-2">
                <label htmlFor="upload-button-two" className="label-profile">
                  <img src={placeholder} alt="take" />{" "}
                  {multipleImage.length > 0
                    ? multipleImage.map((x) => x.name).join(", ")
                    : "Upload max of four images"}
                </label>
                <input
                  type="file"
                  name={multipleImage}
                  id="upload-button-two"
                  style={{ display: "none" }}
                  multiple
                  onChange={handleChangeMultiple}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">Upload Investors</h2>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://nairametrics.com/wp-content/uploads/2023/06/yieldroom-data-1.csv"
              >
                See format
              </a>
              <div className="img-upload p-2">
                <label htmlFor="upload-button-3" className="label-profile">
                  <img src={placeholder} alt="take" />
                  {investors ? investors.name : "Upload Investors"}
                </label>
                <input
                  type="file"
                  id="upload-button-3"
                  name={investors}
                  style={{ display: "none" }}
                  onChange={handleInvestorsInput}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 text-center">
            <Button
              type="button"
              onClick={newInvestmentButton}
              disabled={
                !(
                  inputValues.investment_name &&
                  inputValues.investment_location &&
                  inputValues.investment_features &&
                  inputValues.investment_description &&
                  inputValues.investment_video &&
                  inputValues.investment_volume &&
                  startDate &&
                  multipleImage &&
                  image &&
                  investors &&
                  endDate &&
                  constructionStatus &&
                  titleStatus &&
                  projectStatus
                )
              }
            >
              {loading ? "Loading..." : "Upload Investment"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
