import React from "react";
import "antd/dist/antd.min.css";
import "./assets/css/App.css";
import "./assets/css/Custom.css";
import "react-phone-input-2/lib/style.css";

import AllPages from "./routes/routes";

function App() {
  return <AllPages />;
}

export default App;
