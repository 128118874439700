import { createSlice } from "@reduxjs/toolkit";
import makeAPICall from "../../../utils/apiUtils";
import history from "../../history";
import { AUTH_TOKEN, REDIRECT_URL } from "../../../utils/constants";
import { message } from "antd";

const initialState = {
  loading: false,
  hasErrors: null,
  user: {},
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure } = loginSlice.actions;

// A selector
export const getLoginSelector = (state) => state.login;

// The reducer
export default loginSlice.reducer;

// api call action
export const loginUser = (data) => (dispatch) => {
  dispatch(getApp());
  return makeAPICall({
    path: "/auth/login/",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      // console.log(res, "login successful");
      if (
        res.is_staff === true &&
        res.is_approved === true &&
        res.is_issuer === false
      ) {
        dispatch(getAppSuccess(res));
        window.localStorage.setItem(AUTH_TOKEN, res?.tokens?.access);
        window.localStorage.setItem("REFRESH_TOKEN", res?.tokens?.refresh);
        const redirectUrl =
          window.sessionStorage.getItem(REDIRECT_URL) ?? "/admin";
        history.push(redirectUrl);
        window.location.reload();
      } else if (
        res.is_staff === false &&
        res.is_approved === true &&
        res.is_issuer === false
      ) {
        dispatch(getAppSuccess(res));
        window.localStorage.setItem(AUTH_TOKEN, res?.tokens?.access);
        window.localStorage.setItem("REFRESH_TOKEN", res?.tokens?.refresh);
        const redirectUrl =
          window.sessionStorage.getItem(REDIRECT_URL) ?? "/home";
        history.push(redirectUrl);
        window.location.reload();
      } else if (
        res.is_staff === false &&
        res.is_approved === true &&
        res.is_issuer === true
      ) {
        dispatch(getAppSuccess(res));
        window.localStorage.setItem(AUTH_TOKEN, res?.tokens?.access);
        window.localStorage.setItem("REFRESH_TOKEN", res?.tokens?.refresh);
        const redirectUrl =
          window.sessionStorage.getItem(REDIRECT_URL) ?? "/issuer";
        history.push(redirectUrl);
        window.location.reload();
      } else {
        const redirectUrl =
          window.sessionStorage.getItem(REDIRECT_URL) ?? "/pending";
        history.push(redirectUrl);
        window.location.reload();
      }
    })
    .catch((err) => {
      // console.log(err.message, "login error")
      message.error(err.message, 5);
      dispatch(getAppFailure(err));
    });
};
