import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import transactionIcon from "../../../assets/img/Activity.svg";
import investorsIcon from "../../../assets/img/User.svg";
import growIcon from "../../../assets/img/grow.svg";
import cartIcon from "../../../assets/img/cart.svg";
import backbtn from "../../../assets/img/Back.svg";
import ticket from "../../../assets/img/Ticket.png";
import disconnect from "../../../assets/img/disconnect.png";
//import editIcon from "../../../assets/img/edit_icon.png";
import placeholder from "../../../assets/img/tied.png";
// import editIcon from "../../../assets/img/Edit.svg";
import chartIcon from "../../../assets/img/Chart.svg";
import reduceIcon from "../../../assets/img/reduce.svg";
import Button from "../../../components/Button";
import { Table, message, Drawer, Input, DatePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";

// import { investments } from "./table";
import { formatMoney } from "../../../utils/helperFunctions";
import moment from "moment";
import UploadInvestment from "./uploadInvestment";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentInvestmentSelector,
  GetInvestmentUser,
} from "../../../services/slices/getInvestment";
import makeAPICall from "../../../utils/apiUtils";
import makeAPICallForm from "../../../utils/apiUtilsForm";
import InputField from "../../../components/InputField";
import { SponsorUser } from "../../../services/slices/createSponsor";
import { InvestorUser } from "../../../services/slices/createInvestor";

const { Search } = Input;

export default function IssuerDashboard() {
  const dispatch = useDispatch();
  // use the hook and selector
  const { loading, investment } = useSelector(getCurrentInvestmentSelector);
  const [views, setViews] = useState(false);
  //const [viewsInvestment, setViewsInvestment] = useState(false);
  const [current, setCurrent] = useState(1);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [isLoadingClose, setIsLoadingClose] = useState({
    loading: false,
    key: 0,
  });
  const [open, setOpen] = useState(false);
  const [openSponsor, setOpenSponsor] = useState(false);
  const [openInvestor, setOpenInvestor] = useState(false);
  const [openEditInvestor, setOpenEditInvestor] = useState(false);
  const [userTable, setUserTable] = useState(investment);

  const [inputValues, setInputValues] = useState({
    investment_name: "",
    investment_price: "",
    investment_location: "",
    investment_description: "",
    investment_features: "",
    investment_return: "",
    investment_annual: "",
    name: "",
    address: "",
    phone: "",
    nin: "",
    firstname: "",
    lastname: "",
    email: "",
    next_of_kin: "",
    house_number: "",
    volume: "",
    amount: "",
    payment: "",
    titleStatus: "",
    constructionStatus: "",
    projectStatus: "",
  });

  const [dob, setDob] = useState();
  const [identity, setIdentity] = useState();
  //const [period, setPeriod] = useState();
  //const [category, setCategory] = useState();
  //const [investPeriod, setInvestPeriod] = useState("");
  //const [investCategory, setInvestCategory] = useState("");
  //const [risk, setRisk] = useState();
  //const [investRisk, setInvestRisk] = useState("");
  const [immediate, setImmediate] = useState();
  const [summary, setSummary] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [investmentView, setInvestmentView] = useState(false);
  //const [anotherLoading, setAnotherLoading] = useState(false);
  //const [modalLoading, setModalLoading] = useState(false);
  //const [currentTwo, setCurrentTwo] = useState(1);
  //const [isModalOpen, setIsModalOpen] = useState(false);
  const [singleInvestment, setSingleInvestment] = useState();
  const [loadingNow, setLoading] = useState(false);

  const onChangeDob = (date, dateString) => {
    // console.log(dateString);
    setDob(moment(dateString));
  };
  const onChangeStart = (date, dateString) => {
    // console.log(dateString);
    setStartDate(moment(dateString));
  };

  const onChangeEnd = (date, dateString) => {
    // console.log(dateString);
    setEndDate(moment(dateString));
  };
  const showDrawer = (row) => {
    setInputValues({
      investment_name: row?.name,
      investment_price: row?.amount,
      investment_location: row?.location,
      investment_description: row?.description,
      investment_features: row?.features,
      investment_return: row?.roi,
      investment_annual: row?.annualized,
    });
    setStartDate(row?.start_date);
    setEndDate(row?.end_date);
    //setInvestCategory(row?.room?.id);
    //setInvestPeriod(row?.period?.id);
    //setInvestRisk(row?.risk?.id);
    // console.log(row);
    setImmediate(row);
    setOpen(true);
  };

  const handleChangeIdentity = (e) => {
    const uploadFile = e.target.files[0];
    if (uploadFile) {
      const fileType = uploadFile["type"];
      const validImageTypes = ["image/png", "image/jpeg"];
      if (validImageTypes.includes(fileType)) {
        setIdentity(uploadFile);
      } else {
        console.log("Please select an image to upload");
      }
    }
  };
  const showDrawerSponsor = (row) => {
    setInputValues({
      name: inputValues.name,
      nin: inputValues.nin,
      phone: inputValues.phone,
      dob: dob,
      address: inputValues.address,
      identity: identity,
    });
    setImmediate(row);
    setOpenSponsor(true);
  };

  const showDrawerInvestor = (row) => {
    setInputValues({
      firstname: inputValues.firstname,
      lastname: inputValues.lastname,
      email: inputValues.email,
      next_of_kin: inputValues.next_of_kin,
      house_number: inputValues.house_number,
      nin: inputValues.nin,
      amount: inputValues.amount,
      payment: inputValues.payment,
      phone: inputValues.phone,
      dob: dob,
      address: inputValues.address,
      identity: identity,
      volume: inputValues.volume,
    });
    setImmediate(row);
    setOpenInvestor(true);
  };

  const showDrawerEditInvestor = (row) => {
    setInputValues({
      house_number: inputValues.house_number,
      amount: inputValues.amount,
      payment: inputValues.payment,
    });
    setImmediate(row);
    setOpenEditInvestor(true);
  };
  const onClose = () => {
    setOpen(false);
    setOpenSponsor(false);
    setOpenInvestor(false);
    setOpenEditInvestor(false);
  };

  const showView = () => {
    setViews(true);
    setInvestmentView(false);
  };

  const hideView = () => {
    setViews(false);
    setInvestmentView(false);
  };

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }
  const approve = (id, verified) => {
    if (verified) {
      const data = {
        is_verified: "False",
      };
      setIsLoading({ loading: true, key: id });
      return makeAPICall({
        path: `/investment/approve/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoading({ loading: false, key: 0 });
          dispatch(GetInvestmentUser({}));
        })
        .catch((err) => {
          setIsLoading({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    } else {
      const data = {
        is_verified: "True",
      };
      setIsLoading({ loading: true, key: id });
      return makeAPICall({
        path: `/investment/approve/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoading({ loading: false, key: 0 });
          dispatch(GetInvestmentUser({}));
        })
        .catch((err) => {
          setIsLoading({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    }
  };
  const closeInvestment = (id, closed) => {
    if (closed) {
      const data = {
        is_closed: "False",
      };
      setIsLoadingClose({ loading: true, key: id });
      return makeAPICall({
        path: `/investment/close/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoadingClose({ loading: false, key: 0 });
          dispatch(GetInvestmentUser({}));
        })
        .catch((err) => {
          setIsLoadingClose({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    } else {
      const data = {
        is_closed: "True",
      };
      setIsLoadingClose({ loading: true, key: id });
      return makeAPICall({
        path: `/investment/close/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoadingClose({ loading: false, key: 0 });
          dispatch(GetInvestmentUser({}));
        })
        .catch((err) => {
          setIsLoadingClose({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    }
  };

  const deleteInvestor = (id) => {
    setIsLoadingClose({ loading: true, key: id });
    return makeAPICall({
      path: `/investment/admin/remove/investor/${id}`,
      //payload: data,
      method: "POST",
    })
      .then((res) => {
        setIsLoadingClose({ loading: false, key: 0 });
        setViews(true);
        dispatch(GetInvestmentUser({}));
      })
      .catch((err) => {
        setIsLoadingClose({ loading: false, key: 0 });
        setViews(false);
        message.error(err.message, 5);
      });
  };

  function showInvestment(row) {
    setInvestmentView(true);
    setImmediate(row);
    setViews(false);
    return makeAPICall({
      path: `/investment/portfolio/${row.slug}`,
      method: "GET",
      params: null,
    })
      .then((res) => {
        setViews(false);
        setSingleInvestment(res);
      })
      .catch((err) => {
        setViews(false);
        message.error(err.message, 5);
      });
  }
  const columnsInvestor = [
    {
      title: "Firstname",
      dataIndex: "firstname",
      key: "firstname",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>
          {row?.investor?.firstname}
        </p>
      ),
    },
    {
      title: "Lastname",
      dataIndex: "lastname",
      key: "lastname",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.investor?.lastname}</p>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.investor?.phone}</p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>
          {formatMoney(row?.amount)}
        </p>
      ),
    },
    {
      title: "Payment Type",
      dataIndex: "payment",
      key: "payment",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.payment}</p>
      ),
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      render: (id, row) => <p>{row?.investor?.email}</p>,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => {
        return (
          <div>
            <Button
              type="button"
              onClick={() => showDrawerEditInvestor(row)}
              style={{ backgroundColor: "transparent", color: "#ED8428" }}
            >
              {isLoadingClose.loading && isLoadingClose.key === row.id
                ? "Loading..."
                : "Edit"}
            </Button>
            <Button
              type="button"
              onClick={() => deleteInvestor(row?.id)}
              style={{ backgroundColor: "transparent", color: "#FF0000" }}
            >
              {isLoadingClose.loading && isLoadingClose.key === row.id
                ? "Loading..."
                : "Delete"}
            </Button>
          </div>
        );
      },
    },
  ];
  const columnsSponsor = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.sponsor?.name}</p>
      ),
    },
    {
      title: "NIN",
      dataIndex: "nin",
      key: "nin",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.sponsor?.nin}</p>
      ),
    },
    {
      title: "Phone",
      dataIndex: "sponsor.phone",
      key: "sponsor.phone",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.sponsor?.phone}</p>
      ),
    },
    {
      title: "Date of birth",
      dataIndex: "dob",
      key: "dob",
      render: (id, row) => <p>{row?.sponsor?.dob}</p>,
    },
  ];
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("L"),
    },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      render: (name) => <p style={{ textTransform: "capitalize" }}>{name}</p>,
    },
    {
      title: "Category",
      dataIndex: "id",
      key: "id",
      render: (room, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.room?.name}</p>
      ),
    },
    {
      title: "Issuer",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p>
          {row?.owner?.firstname}&nbsp; {row?.owner?.lastname}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "is_verified",
      key: "is_verified",
      width: "100px",
      render: (is_verified, row) => {
        if (is_verified) {
          return (
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                onClick={() => showDrawer(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                Edit
              </Button>
              <Button
                type="button"
                onClick={() => showDrawerSponsor(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                Create Sponsor
              </Button>
              <Button
                type="button"
                onClick={() => showDrawerInvestor(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                Add Investor
              </Button>
              <Button
                type="button"
                onClick={() => showInvestment(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                View Portfolio
              </Button>
              <Button
                type="button"
                onClick={() => approve(row.id, is_verified)}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Disapprove"}
              </Button>
            </div>
          );
        } else {
          return (
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                onClick={() => showDrawer(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                Edit
              </Button>
              <Button
                type="button"
                onClick={() => showDrawerSponsor(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                Create Sponsor
              </Button>
              <Button
                type="button"
                onClick={() => showDrawerInvestor(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                Add Investor
              </Button>
              <Button
                type="button"
                onClick={() => showInvestment(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                View Portfolio
              </Button>
              <Button
                type="button"
                onClick={() => approve(row.id, is_verified)}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Approve"}
              </Button>
            </div>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "is_closed",
      key: "is_closed",
      render: (is_closed, row) => {
        if (is_closed) {
          return (
            <Button
              type="button"
              onClick={() => closeInvestment(row.id, is_closed)}
              style={{ backgroundColor: "#EA8745" }}
            >
              {isLoadingClose.loading && isLoadingClose.key === row.id
                ? "Loading..."
                : "Close"}
            </Button>
          );
        } else {
          return (
            <Button
              type="button"
              onClick={() => closeInvestment(row.id, is_closed)}
              style={{ backgroundColor: "#14B941" }}
            >
              {isLoadingClose.loading && isLoadingClose.key === row.id
                ? "Loading..."
                : "Open"}
            </Button>
          );
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(GetInvestmentUser({}));
  }, [dispatch]);

  function getData(item) {
    // console.log(item, 'sjsjs')
    setCurrent(item.current);
    dispatch(
      GetInvestmentUser({
        params: { page: item.current },
      })
    );
  }

  useEffect(() => {
    const summary = () => {
      return makeAPICall({
        path: "/investment/issuer/summary/",
        method: "GET",
      })
        .then((res) => {
          setSummary(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    summary();
  }, []);

  const newInvestmentButton = () => {
    const formData = new FormData();
    // formData.append("id", immediate.id);
    formData.append("name", inputValues.investment_name);
    formData.append("description", inputValues.investment_description);
    formData.append("features", inputValues.investment_features);
    formData.append("video", inputValues.video);
    formData.append("start_date", startDate.format("YYYY-MM-DD"));
    formData.append("end_date", endDate.format("YYYY-MM-DD"));
    formData.append("roi", inputValues.investment_return);
    formData.append("project_status", inputValues.projectStatus);
    formData.append("title_status", inputValues.titleStatus);
    formData.append("construction_status", inputValues.constructionStatus);
    formData.append("location", inputValues.investment_location);
    formData.append("is_verified", immediate.is_verified ? "True" : "False");
    formData.append("is_closed", immediate.is_closed ? "True" : "False");
    setLoadingEdit(true);
    return makeAPICallForm({
      path: `/investment/invest/${immediate.id}`,
      payload: formData,
      method: "PUT",
    })
      .then((res) => {
        setLoadingEdit(false);
        setOpen(false);
        message.success("Your investment was updated successfully", 5);
        dispatch(GetInvestmentUser({}));
      })
      .catch((err) => {
        setLoadingEdit(false);
        console.log(err);
      });
  };

  const updateInvestorButton = () => {
    const formData = new FormData();
    // formData.append("id", immediate.id);
    formData.append("payment", inputValues.payment);
    formData.append("house_number", inputValues.house_number);
    formData.append("amount", inputValues.amount);
    setLoadingEdit(true);
    return makeAPICallForm({
      path: `/investor/update/${immediate.id}`,
      payload: formData,
      method: "PUT",
    })
      .then((res) => {
        setLoadingEdit(false);
        setOpen(false);
        message.success("Your investor was updated successfully", 5);
        dispatch(GetInvestmentUser({}));
      })
      .catch((err) => {
        setLoadingEdit(false);
        console.log(err);
      });
  };

  const newSponsorButton = () => {
    console.log(identity);
    const formData = new FormData();
    const investmentId = immediate.id;
    formData.append("name", inputValues.name);
    formData.append("phone", inputValues.phone);
    formData.append("nin", inputValues.nin);
    formData.append("dob", dob.format("YYYY-MM-DD"));
    formData.append("address", inputValues.address);
    formData.append("identity", identity);

    dispatch(SponsorUser(formData, investmentId));
  };

  const newInvestorButton = () => {
    console.log(identity);
    const formData = new FormData();
    const investmentId = immediate.id;
    formData.append("firstname", inputValues.firstname);
    formData.append("phone", inputValues.phone);
    formData.append("nin", inputValues.nin);
    formData.append("amount", inputValues.amount);
    formData.append("payment", inputValues.payment);
    formData.append("dob", dob.format("YYYY-MM-DD"));
    formData.append("address", inputValues.address);
    formData.append("identity", identity);
    formData.append("lastname", inputValues.lastname);
    formData.append("email", inputValues.email);
    formData.append("house_number", inputValues.house_number);
    formData.append("volume", inputValues.volume);
    formData.append("next_of_kin", inputValues.next_of_kin);

    dispatch(InvestorUser(formData, investmentId));
  };

  const onSearch = (value) => {
    setLoading(true);
    return makeAPICall({
      path: `/investment/investment/?search=${value}`,
      method: "GET",
      params: null,
    })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  };

  return (
    <AdminBackBone title="Dashboard" onClick={showView}>
      {views ? (
        <UploadInvestment close={hideView} />
      ) : investmentView ? (
        <div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div
                className="d-flex align-items-center"
                onClick={hideView}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={backbtn}
                  alt="go back"
                  style={{ marginRight: "10px" }}
                />
                <h1 className="back-text">Investment details</h1>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-3 p-4">
            <div className="row">
              <div className="col-md-6 major-invest">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h1 style={{ textTransform: "capitalize" }}>
                      <span className="orange">{singleInvestment?.name}</span>{" "}
                    </h1>
                  </div>
                  <div>
                    <h2>{singleInvestment?.volume}</h2>
                    <p>Volume of units</p>
                  </div>
                  <div>
                    <h2>{singleInvestment?.owner?.phone}</h2>
                    <p>Phone Number</p>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h3>Description</h3>
                    <p>{singleInvestment?.description}</p>
                  </div>
                  <div>
                    <h3
                      className={
                        singleInvestment?.is_closed ? "orange" : "green"
                      }
                    >
                      {singleInvestment?.is_closed ? "Inactive" : "Active"}
                    </h3>
                    <p>Status</p>
                  </div>
                </div>
                <br />
                <br />
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h3>Features</h3>
                    {singleInvestment?.features
                      ?.split(",")
                      ?.slice(0, 3)
                      .map((feature, index) => (
                        <h2 className="listing-properties" key={index}>
                          {feature}
                        </h2>
                      ))}
                  </div>
                </div>
                <br />
                <br />
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h3>Title status</h3>
                    <p>{singleInvestment?.title_status}</p>
                  </div>
                  <div>
                    <h2>Construction status</h2>
                    <p>{singleInvestment?.construction_status}</p>
                  </div>
                  <div>
                    <h2>Project status</h2>
                    <p>{singleInvestment?.project_status}</p>
                  </div>
                </div>
                <br />
                <br />
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h3>Start date</h3>
                    <p>{singleInvestment?.start_date}</p>
                  </div>
                  <div>
                    <h2>End date</h2>
                    <p>{singleInvestment?.end_date}</p>
                  </div>
                </div>
                <br />
                <br />
                <div className="d-flex align-items-center justify-content-around">
                  <div className="d-flex align-items-center">
                    <img
                      src={ticket}
                      alt="go back"
                      style={{
                        marginRight: "10px",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                    <span className="play-dis">Offer Discount Promo</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={disconnect}
                      alt="go back"
                      style={{ marginRight: "10px", width: "15px" }}
                    />
                    <span className="play-dis" style={{ color: "#CC2E2E" }}>
                      Deactivate Investor
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="investor-invest-bg p-4">
                  <p className="mb-3">Total Investment Value</p>
                  <h1 className="mb-5">
                    {immediate?.currency?.name} {formatMoney(immediate?.amount)}
                  </h1>
                  <p className="mb-3">Investment Count</p>
                  <h2>{singleInvestment?.investorsCount}</h2>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <h1 className="back-text">Sponsor details</h1>
                {singleInvestment?.sponsor?.length > 0 ? (
                  <Table
                    columns={columnsSponsor}
                    dataSource={singleInvestment?.sponsor}
                    rowKey="id"
                    scroll={{ x: "max-content" }}
                    pagination={false}
                  />
                ) : (
                  "No Sponsor"
                )}
              </div>
            </div>
            <br />
            <hr />
            <div className="row">
              <div className="col-md-12">
                <h1 className="back-text">List of investors</h1>
                {singleInvestment?.investors?.length > 0 ? (
                  <Table
                    columns={columnsInvestor}
                    dataSource={singleInvestment?.investors}
                    rowKey="id"
                    scroll={{ x: "max-content" }}
                    pagination={false}
                  />
                ) : (
                  "No Investor"
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {" "}
          <div className="row row-grid">
            <div className="col-md-3">
              <div className="analytics-tab">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="analytics-icon"
                    style={{ backgroundColor: "rgba(234, 67, 54, 0.1)" }}
                  >
                    <img src={transactionIcon} alt="analytics" />
                  </div>
                  <h1>Total Projects</h1>
                </div>
                <div className="d-flex align-items-center justify-content-between analytics-light">
                  <h2>{summary?.totalInvestments}</h2>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="green-bg">
                      <img src={growIcon} alt="analytics up" />
                    </div>
                    <h3 className="green">%</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="analytics-tab">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="analytics-icon"
                    style={{ backgroundColor: "#FBECDB" }}
                  >
                    <img src={investorsIcon} alt="analytics" />
                  </div>
                  <h1>Total Investors</h1>
                </div>
                <div className="d-flex align-items-center justify-content-between analytics-light">
                  <h2>{summary?.totalInvestors}</h2>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="orange-bg">
                      <img src={reduceIcon} alt="analytics up" />
                    </div>
                    <h3>%</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="analytics-tab">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="analytics-icon"
                    style={{ backgroundColor: "#E8F5F1" }}
                  >
                    <img src={cartIcon} alt="analytics" />
                  </div>
                  <h1>Total Sponsors</h1>
                </div>
                <div className="d-flex align-items-center justify-content-between analytics-light">
                  <h2>{summary?.totalSponsors}</h2>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="orange-bg">
                      <img src={reduceIcon} alt="analytics up" />
                    </div>
                    <h3>%</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="analytics-tab">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="analytics-icon"
                    style={{ backgroundColor: "#E0EEFF" }}
                  >
                    <img src={chartIcon} alt="analytics" />
                  </div>
                  <h1>Total Units</h1>
                </div>
                <div className="d-flex align-items-center justify-content-between analytics-light">
                  <h2>{summary?.totalVolume?.volume}</h2>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="green-bg">
                      <img src={growIcon} alt="analytics up" />
                    </div>
                    <h3 className="green">%</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row row-grid">
            <div className="col-md-9"></div>
            <div className="col-md-3"></div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="table-records">
                <div className="d-flex justify-content-between mb-2">
                  <h1>Recent Investments</h1>
                  {/* <p>See more</p> */}
                  <div>
                    <Search
                      placeholder="Search Investments"
                      onSearch={onSearch}
                      size="large"
                    />
                  </div>
                </div>
                {loading || loadingNow ? (
                  "Loading..."
                ) : (
                  <Table
                    columns={columns}
                    dataSource={userTable?.results}
                    rowKey="id"
                    pagination={{
                      showSizeChanger: true,
                      total: userTable?.count,
                      current: current,
                    }}
                    onChange={(pagination) => {
                      getData(pagination);
                    }}
                    scroll={{ x: "max-content" }}
                  />
                )}
              </div>
            </div>
          </div>{" "}
        </div>
      )}
      <Drawer
        title="Edit Investment"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div className="row investment-form-fields">
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Investment Name</h2>
              <InputField
                type="text"
                value={inputValues.investment_name}
                onChange={handleChangeInput}
                name="investment_name"
                placeholder="Give the investment a name"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Number of Units</h2>
              <Input
                prefix="₦"
                onChange={handleChangeInput}
                value={inputValues.volume}
                name="volume"
                type="number"
                placeholder="0,00"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Location of the Investment</h2>
              <InputField
                type="text"
                value={inputValues.investment_location}
                onChange={handleChangeInput}
                name="investment_location"
                placeholder="Enter investment location"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Video</h2>
              <InputField
                value={inputValues.investment_return}
                onChange={handleChangeInput}
                name="investment_return"
                type="text"
                placeholder="Enter returns e.g. 10%"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Description</h2>
              <InputField
                type="text"
                value={inputValues.investment_description}
                onChange={handleChangeInput}
                name="investment_description"
                placeholder="Describe the investment"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">
                Feature List &nbsp;
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Enter these Number of rooms, land size, Title Approval State
                    - completed, Construction Approval State - in progress,
                    Number of floors - 5, Developer - (Chimauche Njoku),
                    Architect - (Emmanuel Chilaka), QS - (Jaja Nonso), EA -
                    (Demo Usman), ME - (Sample Bukky), Registration Stratis -
                    (Completed)
                  </span>
                </div>
              </h2>
              <TextArea
                value={inputValues.investment_features}
                onChange={handleChangeInput}
                name="investment_features"
                placeholder="Add features of the investment separated with comma, eg Number of floors - 5, Number of room - 15, Developer: Sanchez Consultant"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Title Status</h2>
              <select
                onChange={handleChangeInput}
                className="select-area"
                name="titleStatus"
                value={inputValues.titleStatus}
              >
                <option>--Choose an option--</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Construction Status</h2>
              <select
                onChange={handleChangeInput}
                className="select-area"
                name="constructionStatus"
                value={inputValues.constructionStatus}
              >
                <option>--Choose an option--</option>
                <option value="not started">Not started</option>
                <option value="in progress">In progress</option>
                <option value="completed">Completed</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Project Status</h2>
              <select
                onChange={handleChangeInput}
                className="select-area"
                name="projectStatus"
                value={inputValues.projectStatus}
              >
                <option>--Choose an option--</option>
                <option value="not started">Not started</option>
                <option value="in progress">In progress</option>
                <option value="completed">Completed</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Start Date</h2>
              <InputField
                value={startDate}
                onChange={onChangeStart}
                name="start_date"
                type="date"
                placeholder="YYYY-MM-DD"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">End Date</h2>
              <InputField
                value={endDate}
                onChange={onChangeEnd}
                name="end_date"
                type="date"
                placeholder="YYYY-MM-DD"
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <Button
              type="button"
              onClick={newInvestmentButton}
              disabled={
                !(
                  inputValues.investment_name &&
                  inputValues.volume &&
                  startDate &&
                  inputValues.investment_location &&
                  inputValues.investment_features &&
                  inputValues.investment_description &&
                  endDate &&
                  inputValues.projectStatus &&
                  inputValues.constructionStatus &&
                  inputValues.titleStatus
                )
              }
            >
              {loadingEdit ? "Loading..." : "Update Investment"}
            </Button>
          </div>
        </div>
      </Drawer>
      <Drawer
        title="Add Sponsor"
        placement="right"
        onClose={onClose}
        open={openSponsor}
      >
        <div className="row investment-form-fields">
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Sponsor Full Name</h2>
              <InputField
                type="text"
                value={inputValues.name}
                onChange={handleChangeInput}
                name="name"
                placeholder="Give the Sponsor name"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Date of birth</h2>
              <DatePicker value={dob} onChange={onChangeDob} />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">National Indentification Number (NIN)</h2>
              <Input
                onChange={handleChangeInput}
                value={inputValues.nin}
                name="nin"
                type="text"
                placeholder="Enter your National Indentification Number (NIN)"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Phone Number</h2>
              <InputField
                type="text"
                value={inputValues.phone}
                onChange={handleChangeInput}
                name="phone"
                placeholder="Enter phone number"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Address</h2>
              <TextArea
                value={inputValues.address}
                onChange={handleChangeInput}
                name="address"
                placeholder="Enter sponsor address"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">
                Upload Identity (Passpord, driving license)
              </h2>
              <label htmlFor="upload-button" className="label-profile">
                <img src={placeholder} alt="take" />
                {identity ? identity.name : "Upload Image"}
              </label>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChangeIdentity}
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <Button
              type="button"
              onClick={newSponsorButton}
              disabled={
                !(
                  inputValues.name &&
                  inputValues.nin &&
                  inputValues.phone &&
                  inputValues.address &&
                  dob &&
                  identity
                )
              }
            >
              {loadingEdit ? "Loading..." : "Add Sponsor"}
            </Button>
          </div>
        </div>
      </Drawer>
      <Drawer
        title="Add Investor"
        placement="right"
        onClose={onClose}
        open={openInvestor}
      >
        <div className="row investment-form-fields">
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">First Name</h2>
              <InputField
                type="text"
                value={inputValues.firstname}
                onChange={handleChangeInput}
                name="firstname"
                placeholder="Give the Investor first name"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Last Name</h2>
              <InputField
                type="text"
                value={inputValues.lastname}
                onChange={handleChangeInput}
                name="lastname"
                placeholder="Give the Investor last name"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Email</h2>
              <InputField
                type="email"
                value={inputValues.email}
                onChange={handleChangeInput}
                name="email"
                placeholder="Give the Investor email address"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Date of birth</h2>
              <DatePicker value={dob} onChange={onChangeDob} />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">National Indentification Number (NIN)</h2>
              <Input
                onChange={handleChangeInput}
                value={inputValues.nin}
                name="nin"
                type="text"
                placeholder="Enter your National Indentification Number (NIN)"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Phone Number</h2>
              <InputField
                type="text"
                value={inputValues.phone}
                onChange={handleChangeInput}
                name="phone"
                placeholder="Enter phone number"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Address</h2>
              <TextArea
                value={inputValues.address}
                onChange={handleChangeInput}
                name="address"
                placeholder="Enter sponsor address"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Volume of units</h2>
              <InputField
                type="text"
                value={inputValues.volume}
                onChange={handleChangeInput}
                name="volume"
                placeholder="Enter volume of units bought"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Amount</h2>
              <InputField
                type="text"
                value={inputValues.amount}
                onChange={handleChangeInput}
                name="amount"
                placeholder="Enter amount paid"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Payment Type</h2>
              <select
                onChange={handleChangeInput}
                className="select-area"
                name="titleStatus"
                value={inputValues.payment}
              >
                <option>--Choose an option--</option>
                <option value="not started">Not Started</option>
                <option value="partial">Partial</option>
                <option value="full">Full</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Unit number</h2>
              <InputField
                type="text"
                value={inputValues.house_number}
                onChange={handleChangeInput}
                name="house_number"
                placeholder="Give the Investor unit number"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Investor next of kin</h2>
              <InputField
                type="text"
                value={inputValues.next_of_kin}
                onChange={handleChangeInput}
                name="next_of_kin"
                placeholder="Give the Investor next of kin"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">
                Upload Identity (Passpord, driving license)
              </h2>
              <label htmlFor="upload-button" className="label-profile">
                <img src={placeholder} alt="take" />
                {identity ? identity.name : "Upload Image"}
              </label>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChangeIdentity}
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <Button
              type="button"
              onClick={newInvestorButton}
              disabled={
                !(
                  inputValues.firstname &&
                  inputValues.lastname &&
                  inputValues.nin &&
                  inputValues.phone &&
                  inputValues.address &&
                  inputValues.next_of_kin &&
                  inputValues.email &&
                  inputValues.amount &&
                  inputValues.payment &&
                  inputValues.house_number &&
                  inputValues.volume &&
                  dob &&
                  identity
                )
              }
            >
              {loadingEdit ? "Loading..." : "Add Investor"}
            </Button>
          </div>
        </div>
      </Drawer>
      <Drawer
        title="Edit Investor"
        placement="right"
        onClose={onClose}
        open={openEditInvestor}
      >
        <div className="row investment-form-fields">
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Payment Status</h2>
              <select
                onChange={handleChangeInput}
                className="select-area"
                name="payment"
                value={inputValues.payment}
              >
                <option>--Choose an option--</option>
                <option value="not started">Not started</option>
                <option value="partial">Partial</option>
                <option value="full">Full</option>
              </select>
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Unit number</h2>
              <Input
                onChange={handleChangeInput}
                value={inputValues.house_number}
                name="house_number"
                type="text"
                placeholder="Enter unit number"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Amount</h2>
              <InputField
                type="text"
                value={inputValues.amount}
                onChange={handleChangeInput}
                name="amount"
                placeholder="Enter amount"
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <Button
              type="button"
              onClick={updateInvestorButton}
              disabled={
                !(
                  inputValues.house_number &&
                  inputValues.amount &&
                  inputValues.payment
                )
              }
            >
              {loadingEdit ? "Loading..." : "Update Investor"}
            </Button>
          </div>
        </div>
      </Drawer>
    </AdminBackBone>
  );
}
