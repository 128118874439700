import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import "./home.css";
import backbtn from "../../assets/img/Back.svg";
import heart from "../../assets/img/Vector.png";
import locationNow from "../../assets/img/location.svg";
import star from "../../assets/img/Star.png";
import editIcon from "../../assets/img/edit_icon.png";
import testImage from "../../assets/img/test_image.png";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";
//import InputField from "../../components/InputField";
import { formatDate } from "../../utils/helperFunctions";
import { Modal, Table, message } from "antd";
import makeAPICall from "../../utils/apiUtils";

const baseURL = process.env.REACT_APP_API_ENDPOINT;

//const { Paragraph } = Typography;

export default function SingleInvestment() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  //const [amount, setAmount] = useState("");
  //const [loading, setLoading] = useState(false);
  //const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  //const [immediate, setImmediate] = useState();
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const [contactInput, setContactInput] = useState("");

  const showCommentModal = () => {
    setIsCommentModalOpen(true);
  };
  const showContactModal = () => {
    setIsContactModalOpen(true);
  };

  const handleContactOk = () => {
    if (contactInput === "") {
      message.error("Please send a message to the Issuer");
    } else {
      const data = {
        message: contactInput,
      };
      //setModalLoading(true);
      setIsContactModalOpen(true);
      return makeAPICall({
        path: `/investor/message/${state.id}`,
        method: "POST",
        payload: data,
      })
        .then((res) => {
          setIsContactModalOpen(false);
          //setAccountView(true);
        })
        .catch((err) => {
          //setModalLoading(false);
          setIsContactModalOpen(false);
          // console.log(err);
          message.error(err.message, 5);
        });
    }
  };
  const handleContactCancel = () => {
    setIsContactModalOpen(false);
  };

  const handleCommentOk = () => {
    if (commentInput === "") {
      message.error("Please enter a comment");
    } else {
      const data = {
        comment: commentInput,
      };
      //setModalLoading(true);
      setIsCommentModalOpen(true);
      return makeAPICall({
        path: `/comment/create/investment/${state.id}`,
        method: "POST",
        payload: data,
      })
        .then((res) => {
          setIsCommentModalOpen(false);
          //setAccountView(true);
        })
        .catch((err) => {
          //setModalLoading(false);
          setIsCommentModalOpen(false);
          // console.log(err);
          message.error(err.message, 5);
        });
    }
  };
  const handleCommentCancel = () => {
    setIsCommentModalOpen(false);
  };
  // console.log(state, "test");

  const hideView = () => {
    navigate("/home");
  };

  const columnsComment = [
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: "400px",
    },
    {
      title: "Responded By",
      dataIndex: "responded_by",
      key: "responded_by",
      render: (responded_by) => (
        <p style={{ textTransform: "capitalize" }}>{responded_by?.firstname}</p>
      ),
    },

    {
      title: "Status",
      dataIndex: "is_closed",
      key: "is_closed",
      render: (is_closed) => {
        if (is_closed) {
          return (
            <span
              style={{
                backgroundColor: "#EA8745",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              Closed
            </span>
          );
        } else {
          return (
            <span
              style={{
                backgroundColor: "#14B941",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              Open
            </span>
          );
        }
      },
    },
  ];

  return (
    <div>
      <NavBar />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="row mt-4 mb-2">
                <div className="col-md-12">
                  <div
                    className="d-flex align-items-center"
                    onClick={hideView}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={backbtn}
                      alt="go back"
                      style={{ marginRight: "10px" }}
                    />
                    <h1 className="back-text">Back</h1>
                  </div>
                </div>
              </div>
              <div className="row single-page mb-2">
                <div className="col-md-12">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <h1>{state?.name}</h1>
                      <div className="d-flex align-items-center">
                        <img src={star} className="rating-img" alt="star" />
                        <h2 className="rating">4.5</h2>
                      </div>
                    </div>

                    <img src={heart} alt="favourite" className="favourite" />
                  </div>
                </div>
              </div>
              <div className="row mb-4 single-page-open">
                <h1>
                  {state?.room?.name} -{" "}
                  <span className={state?.is_closed ? "orange" : "green"}>
                    Open Investment
                  </span>
                </h1>
                <div className="d-flex">
                  <img src={locationNow} alt="location" />
                  <p>{state?.location}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="single-featured">
                    <img
                      src={
                        state?.image.length > 0
                          ? `${baseURL}${state?.image[0].gallery_url}`
                          : testImage
                      }
                      alt="listing"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row row-grid">
                    {state?.image.length > 0
                      ? state?.image?.map((item, index) =>
                          item.is_featured === false ? (
                            <div className="col-md-6 mb-3" key={index}>
                              <div className="single-featured-others">
                                <img
                                  src={`${baseURL}${item.gallery_url}`}
                                  alt="listing"
                                />
                              </div>
                            </div>
                          ) : null
                        )
                      : null}
                  </div>
                </div>
              </div>
              <div className="row single-about">
                <div className="col-md-12">
                  <h1>About this Investment</h1>
                  <p>{state?.description}</p>
                </div>
              </div>

              <div className="row row-grid single-features">
                <div className="col-md-7 list-back-single">
                  <div className="row mt-5 mb-5">
                    <div className="col-md-6 offset-md-1">
                      <h2 className="estimate my-4">
                        <span>Status</span>
                      </h2>
                      <div className="d-flex justify-content-between">
                        <div>
                          <h2 className="tenor mb-2">Title</h2>
                          <h2
                            style={{
                              textTransform: "capitalize",
                            }}
                            className="range"
                          >
                            {state?.title_status}
                          </h2>
                        </div>
                        <div>
                          <h2 className="tenor mb-2">Construction</h2>
                          <h2
                            style={{
                              textTransform: "capitalize",
                            }}
                            className="range text-center"
                          >
                            {state?.construction_status}
                          </h2>
                        </div>
                        <div>
                          <h2 className="tenor mb-2">Project</h2>
                          <h2
                            style={{
                              textTransform: "capitalize",
                            }}
                            className="range text-end"
                          >
                            {state?.project_status}
                          </h2>
                        </div>
                      </div>
                      <h2 className="estimate my-4">
                        <span>Period</span>
                      </h2>
                      <div className="row mb-3">
                        <div className="col-md-6 align-items-left">
                          <h4 className="listing-properties">Start</h4>
                          <small>{formatDate(state?.start_date)}</small>
                        </div>
                        <div className="col-md-6 align-items-right">
                          <h4 className="listing-properties">End</h4>
                          <small>{formatDate(state?.end_date)}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-page-open mb-4">
                    <h1>Features</h1>
                  </div>
                  <div className="row">
                    {state?.features?.split(",").map((feature, index) => (
                      <div className="col-md-4 mb-2" key={index}>
                        <h2 style={{ fontSize: "14px" }}>{feature}</h2>
                      </div>
                    ))}
                  </div>
                  {/* <h1>h2hhdhdhd</h1> */}
                  {state?.canInvestorComment ? (
                    <div className="row">
                      <div className="col-md-12">
                        <Button
                          type="button"
                          onClick={showCommentModal}
                          style={{
                            backgroundColor: "transparent",
                            color: "#ED8428",
                            border: "1px solid #ed8428",
                          }}
                        >
                          <img
                            src={editIcon}
                            alt="go back"
                            style={{ marginRight: "10px", width: "15px" }}
                          />{" "}
                          Add comment
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <b></b>
                    </div>
                  )}
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      {state?.comment?.length > 0 ? (
                        <Table
                          columns={columnsComment}
                          dataSource={state?.comment}
                          rowKey="id"
                          scroll={{ x: "max-content" }}
                          pagination={false}
                        />
                      ) : (
                        "No Comments"
                      )}
                    </div>
                  </div>
                  {/* End add comment */}
                </div>
                <div className="col-md-5">
                  <div className="single-details-card text-center bg-white p-5">
                    <h2
                      className={
                        "open-listing mb-3 " +
                        (state?.is_closed ? "orange" : "green")
                      }
                    >
                      {state.is_closed
                        ? "Closed Investment"
                        : "Open Investment"}
                    </h2>
                    <p>From</p>
                    <h3>{formatDate(state?.start_date)}</h3>
                    <p className="my-3">To</p>
                    <h4 className="my-3">{formatDate(state?.end_date)}</h4>
                    <Button
                      type="button"
                      onClick={showContactModal}
                      style={{ width: "80%" }}
                    >
                      Contact Issuer
                    </Button>
                    <p className="mt-3">
                      Total Investors: {state?.investorsCount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            title="Add Comment"
            open={isCommentModalOpen}
            onOk={handleCommentOk}
            onCancel={handleCommentCancel}
          >
            <div className="investment-form-fields">
              <h2>Add a comment</h2>
              <TextArea
                onChange={(e) => setCommentInput(e.target.value)}
                value={commentInput}
                name="description"
                rows="7"
                placeholder="A short paragraph describing the details of this investment."
              />
            </div>
          </Modal>
          <Modal
            title="Contact Issuer"
            open={isContactModalOpen}
            onOk={handleContactOk}
            onCancel={handleContactCancel}
          >
            <div className="investment-form-fields">
              <h2>Add a comment</h2>
              <select
                onChange={(e) => setContactInput(e.target.value)}
                className="select-area"
                name="message"
                value={contactInput}
              >
                <option>--Choose an option--</option>
                <option value="I am interested in the project">
                  I am interested in the project
                </option>
                <option value="I want to know more">I want to know more</option>
                <option value="Well done">Well done</option>
              </select>
            </div>
          </Modal>
        </div>
      </section>
      <Footer />
    </div>
  );
}
