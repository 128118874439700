import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import { Table, message, Input} from "antd";
import { useNavigate } from "react-router-dom";
import makeAPICall from "../../../utils/apiUtils";
import moment from "moment";
import Button from "../../../components/Button";

const { Search } = Input;

export default function UserManagement() {
  const navigate = useNavigate();
  const [views, setViews] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userTable, setUserTable] = useState();
  const [current, setCurrent] = useState(1);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });
  const [isLoadingClose, setIsLoadingClose] = useState({
    loading: false,
    key: 0,
  });

  const getUsers = () => {
    setLoading(true);
    return makeAPICall({
      path: "/auth/list-users/",
      method: "GET",
      params: null,
    })
      .then((res) => {
        setLoading(false);
        setUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (views === true) {
      navigate("/admin");
    }
  }, [views, navigate]);

  const showView = () => {
    setViews(true);
  };

  const verifyIt = (id, verified) => {
    if (verified) {
      const data = {
        is_verified: "False",
      };
      setIsLoading({ loading: true, key: id });
      return makeAPICall({
        path: `/auth/verify/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoading({ loading: false, key: 0 });
          getUsers();
        })
        .catch((err) => {
          setIsLoading({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    } else {
      const data = {
        is_verified: "True",
      };
      setIsLoading({ loading: true, key: id });
      return makeAPICall({
        path: `/auth/verify/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoading({ loading: false, key: 0 });
          getUsers();
        })
        .catch((err) => {
          setIsLoading({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    }
  };

  const approve = (id, closed) => {
    if (closed) {
      const data = {
        is_approved: "False",
      };
      setIsLoadingClose({ loading: true, key: id });
      return makeAPICall({
        path: `/auth/approve/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoadingClose({ loading: false, key: 0 });
          getUsers();
        })
        .catch((err) => {
          setIsLoadingClose({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    } else {
      const data = {
        is_approved: "True",
      };
      setIsLoadingClose({ loading: true, key: id });
      return makeAPICall({
        path: `/auth/approve/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoadingClose({ loading: false, key: 0 });
          getUsers();
        })
        .catch((err) => {
          setIsLoadingClose({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("L"),
    },
    {
      title: "Full Name",
      dataIndex: "firstname",
      key: "firstname",
      render: (firstname, row) => (
        <p style={{ textTransform: "capitalize" }}>
          {firstname}&nbsp; {row.lastname}
        </p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Referral Code",
      dataIndex: "referral_code",
      key: "referral_code",
    },
    {
      title: "Action",
      dataIndex: "is_verified",
      key: "is_verified",
      width: "100px",
      render: (is_verified, row) => {
        if (is_verified) {
          return (
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                onClick={() => verifyIt(row.id, is_verified)}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Unverify"}
              </Button>
            </div>
          );
        } else {
          return (
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                onClick={() => verifyIt(row.id, is_verified)}
                style={{ backgroundColor: "#14B941" }}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Verify"}
              </Button>
            </div>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "is_approved",
      key: "is_approved",
      render: (is_approved, row) => {
        if (is_approved) {
          return (
            <Button
              type="button"
              onClick={() => approve(row.id, is_approved)}
              style={{ backgroundColor: "#EA8745" }}
            >
              {isLoadingClose.loading && isLoadingClose.key === row.id
                ? "Loading..."
                : "Disapprove"}
            </Button>
          );
        } else {
          return (
            <Button
              type="button"
              onClick={() => approve(row.id, is_approved)}
              style={{ backgroundColor: "#14B941" }}
            >
              {isLoadingClose.loading && isLoadingClose.key === row.id
                ? "Loading..."
                : "Approve"}
            </Button>
          );
        }
      },
    },
  ];

  function getData(item) {
    // console.log(item, 'sjsjs')
    setCurrent(item.current);
    return makeAPICall({
      path: "/auth/list-users/",
      method: "GET",
      params: { page: item.current },
    })
      .then((res) => {
        setLoading(false);
        setUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const onSearch = (value) => {
    setLoading(true);
    return makeAPICall({
      path: `/auth/list-users/?s=${value}`,
      method: "GET",
      params: null,
    })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  };

  return (
    <AdminBackBone title="Users" onClick={showView}>
      <div className="row">
        <div className="col-md-12">
          <div className="table-records">
            <div className="d-flex justify-content-between mb-2">
              <h1>All Users</h1>
              <div>
              <Search
                    placeholder="Search Users"
                    onSearch={onSearch}
                    size="large"
                  />
              </div>
            </div>
            {loading ? (
              "Loading..."
            ) : (
              <Table
                columns={columns}
                dataSource={userTable?.results}
                rowKey="id"
                pagination={{
                  showSizeChanger: true,
                  total: userTable?.count,
                  current: current,
                }}
                onChange={(pagination) => {
                  getData(pagination);
                }}
                scroll={{ x: "max-content" }}
              />
            )}
          </div>
        </div>
      </div>
    </AdminBackBone>
  );
}
