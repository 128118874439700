import { createSlice } from "@reduxjs/toolkit";
import makeAPICallForm from "../../utils/apiUtilsForm";
import { message } from "antd";

const initialState = {
  loading: false,
  hasErrors: null,
  newInvestment: {},
};

export const investmentSlice = createSlice({
  name: "investment",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.newInvestment = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
    reset: () => initialState,
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure, reset } = investmentSlice.actions;

// A selector
export const getInvestmentSelector = (state) => state.investment;

// The reducer
export default investmentSlice.reducer;

// api call action
export const InvestmentUser = (data) => (dispatch) => {
  dispatch(getApp());
  return makeAPICallForm({
    path: "/investment/issuer/",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      message.success(
        "Your upload was successful, it will be published when approved by the Admin",
        5
      );
      console.log(res);

      dispatch(getAppSuccess(res));
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(getAppFailure(err));
    });
};

export const revertAll = () => (dispatch) => {
  dispatch(reset());
};
