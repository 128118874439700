// src/services/slices/index.js
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import loginReducer from "./auth/login";
import currentInvestmentReducer from "./getInvestment";
import currentIssuerInvestmentReducer from "./getIssuerInvestment";
import currentSponsorReducer from "./getSponsor";
import registerReducer from "./auth/register";
import registerIssuerReducer from "./auth/registerIssuer";
import investmentReducer from "./createInvestment";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "counter",
  storage,
};

const rootReducer = combineReducers({
  login: loginReducer,
  register: registerReducer,
  registerIssuer: registerIssuerReducer,
  investment: investmentReducer,
  currentInvestment: currentInvestmentReducer,
  currentSponsor: currentSponsorReducer,
  currentIssuerInvestment: currentIssuerInvestmentReducer,
});

const PersistedReducer = persistReducer(persistConfig, rootReducer);

export default PersistedReducer;
