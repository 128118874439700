import React, { useState, useEffect } from "react";
import BackBone from "../../components/BackBone";
import { message } from "antd";
//import { formatDate } from "../../utils/helperFunctions";
//import moment from "moment";
import makeAPICall from "../../utils/apiUtils";
import backbtn from "../../assets/img/Back.svg";
//import TextArea from "../../components/TextArea";
//import editIcon from "../../assets/img/edit_icon.png";
import disconnect from "../../assets/img/disconnect.png";
import ticket from "../../assets/img/Ticket.png";
//import Button from "../../components/Button";

export default function Profile() {
  //const [loading, setLoading] = useState(false);
  //const [userTable, setUserTable] = useState();
  const [investorDetailView, setInvestorDetailView] = useState();
  const [investmentView, setInvestmentView] = useState(true);
  //const [current, setCurrent] = useState(1);
  // Checking buffer
  // remove comment
  //const [modalLoading, setModalLoading] = useState(false);
  //const [isModalOpen, setIsModalOpen] = useState(false);
  //const [immediate, setImmediate] = useState();
  //const [commentInput, setCommentInput] = useState("");

  useEffect(() => {
    getInvestments();
  }, []);

  function getInvestments() {
    //setLoading(true);
    return makeAPICall({
      path: "/auth/investor/",
      method: "GET",
      params: null,
    })
      .then((res) => {
        // console.log(res);
        //setLoading(false);
        //setUserTable(res);
        setInvestmentView(true);
        setInvestorDetailView(res);
      })
      .catch((err) => {
        //setLoading(false);
        message.error(err.message, 5);
      });
  }

  const hideView = () => {
    setInvestmentView(false);
  };

  return (
    <BackBone title="Investments">
      {investmentView ? (
        <div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div
                className="d-flex align-items-center"
                onClick={hideView}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={backbtn}
                  alt="go back"
                  style={{ marginRight: "10px" }}
                />
                <h1 className="back-text">Investment Details</h1>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-3 p-4">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 major-invest">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h1 style={{ textTransform: "capitalize" }}>
                      <span className="orange">
                        {investorDetailView?.user?.firstname},
                      </span>{" "}
                      {investorDetailView?.user?.lastname}
                    </h1>
                  </div>
                  <div>
                    <h2>{investorDetailView?.user?.firstname}</h2>
                    <p>Email</p>
                  </div>
                  <div>
                    <h2>{investorDetailView?.user?.firstname}</h2>
                    <p>Phone Number</p>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h2 style={{ textTransform: "capitalize" }}>
                      {investorDetailView?.user?.firstname}
                    </h2>
                    <p>Business Interest</p>
                  </div>
                  <div>
                    <h2>{investorDetailView?.user?.firstname}</h2>
                    <p>Investment Appetite</p>
                  </div>
                  <div>
                    <p>Status</p>
                  </div>
                </div>
                <br />
                <br />
                <div className="d-flex align-items-center justify-content-around">
                  <div className="d-flex align-items-center">
                    <img
                      src={ticket}
                      alt="go back"
                      style={{
                        marginRight: "10px",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                    <span className="play-dis">Offer Discount Promo</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={disconnect}
                      alt="go back"
                      style={{ marginRight: "10px", width: "15px" }}
                    />
                    <span className="play-dis" style={{ color: "#CC2E2E" }}>
                      Deactivate Investor
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="investor-invest-bg p-4">
                  <p className="mb-3">Investment Count</p>
                  <h2>{investorDetailView?.user?.firstname}</h2>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="table-records">
              <div className="d-flex justify-content-between mb-2">
                <h1>All Investments</h1>
                <div>
                  {/* <Input
                    size="large"
                    placeholder="Search Investments"
                    prefix={<SearchIcon />}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </BackBone>
  );
}
