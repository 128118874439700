import React, { useState } from "react";
import logo from "../../../assets/img/logo.png";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import InputField from "../../../components/InputField";
import { useSelector, useDispatch } from "react-redux";
import {
  getLoginSelector,
  loginUser,
} from "../../../services/slices/auth/login";

export default function Register() {
  const dispatch = useDispatch();
  // use the hook and selector
  const { loading } = useSelector(getLoginSelector);
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const handleSubmit = () => {
    const data = {
      email: inputValues.email,
      password: inputValues.password,
    };
    dispatch(loginUser(data));
  };

  return (
    <section className="step-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row remove-p bg-white">
              <div className="col-md-4">
                <div className="step-div p-5">
                  <img src={logo} alt="Yield room Logo" className="logo" />
                  <div>
                    <h1 className="login-welcome mt-5">Welcome Back</h1>
                    <p className="login-welcome-details">
                      Login to access your investments
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="content-div bg-white p-5">
                  <div className="first-signup">
                    <h1 className="mb-4">Enter your login details</h1>
                    <div>
                      <h2 className="mb-1">Email</h2>
                      <InputField
                        type="email"
                        value={inputValues.email}
                        onChange={handleChangeInput}
                        name="email"
                        placeholder="example@mail.com"
                      />
                    </div>
                    <div className="text-center">
                      <div>
                        <p>
                          <Link to="/forgot-password">
                            <span className="orange">Forget password</span>
                          </Link>{" "}
                        </p>
                      </div>
                    </div>

                    <div>
                      <h2 className="mb-1">Password</h2>
                      <InputField
                        type="password"
                        value={inputValues.password}
                        onChange={handleChangeInput}
                        name="password"
                      />
                    </div>

                    <Button
                      type="button"
                      onClick={handleSubmit}
                      disabled={!(inputValues.password && inputValues.email)}
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        marginTop: "20px",
                      }}
                    >
                      {loading ? "Loading..." : "Login"}
                    </Button>
                    <div className="text-center mt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            <Link to="/signup">
                              <span className="orange">Sign up</span>
                            </Link>{" "}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p>
                            <Link to="/signup/issuer">
                              <span className="orange">Become an issuer</span>
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
