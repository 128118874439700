import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { message, Spin } from "antd";
import makeAPICall from "../../utils/apiUtils";
import logo from "../../assets/img/logo.png";
import Button from "../../components/Button";
import congrats from "../../assets/img/congrtz 1.png";

export default function ConfirmEmail() {
  const navigate = useNavigate();
  let { token } = useParams();
  const isMounted = useRef();
  const [loading, setLoading] = useState(false);
  const [queryParameters] = useSearchParams();

  useEffect(() => {
    if (isMounted.current) return;
    const confirmEmail = (token) => {
      setLoading(true);
      return makeAPICall({
        path: `/auth/email-verify/?token=${queryParameters.get("token")}`,
        method: "GET",
      })
        .then((res) => {
          setLoading(false);
          navigate("/confirm");
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message, 5);
        });
    };
    confirmEmail(token);
    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const gotoLogin = () => {
    navigate("/");
    window.localStorage.removeItem("persist:counter");
  };

  return (
    <section className="step-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row bg-white rounded-3">
              <div className="col-md-12">
                <div className="bg-white p-5">
                  <div className="text-center">
                    {loading ? (
                      <Spin />
                    ) : (
                      <>
                        <img
                          src={logo}
                          alt="Yield room Logo"
                          className="logo mb-3"
                        />
                        <br />
                        <img
                          src={congrats}
                          alt="Yield room Logo"
                          className="mb-3"
                          style={{ width: "200px" }}
                        />
                        <h1 className="congrats mb-3">
                          Congratulations, Your email has been verified.{" "}
                        </h1>
                        <p className="congrats-details mb-5">
                          Welcome to YieldRoom; a platform that gives you
                          amazing investment experience.
                        </p>
                        <Button type="button" onClick={gotoLogin}>
                          Proceed to Login
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
