import { createSlice } from "@reduxjs/toolkit";
//import makeAPICallForm from "../../../utils/apiUtilsForm";
//import makeAPICall from "../../../utils/apiUtils";
import makeAPICallNoTokenForm from "../../../utils/apiUtilsNoTokenForm";
import { message } from "antd";
import history from "../../history";
import { REDIRECT_URL } from "../../../utils/constants";

const initialState = {
  loading: false,
  hasErrors: null,
  registerIssuer: {},
};

export const registerIssuerSlice = createSlice({
  name: "registerIssuer",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.register = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure } = registerIssuerSlice.actions;

// A selector
export const getRegisterIssuerSelector = (state) => state.registerIssuer;

// The reducer
export default registerIssuerSlice.reducer;

// api call action
export const registerIssuerUser = (data) => (dispatch) => {
  dispatch(getApp());
  return makeAPICallNoTokenForm({
    path: data?.referral_code
      ? "/auth/register/referral/"
      : "/auth/register/issuer/",
    payload: data,
    method: "POST",
  })
    .then((res) => {
      // console.log(res, 'register successful');
      dispatch(getAppSuccess(res.data));
      const redirectUrl =
        window.sessionStorage.getItem(REDIRECT_URL) ?? "/congratulations";
      history.push(redirectUrl);
      window.location.reload();
    })
    .catch((err) => {
      message.error(err.message, 5);
      dispatch(getAppFailure(err));
    });
};
