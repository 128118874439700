import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import "../Home/home.css";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
// import Select from "../../components/Select";
//import investmentPlant from "../../assets/img/Frame 238980.png";
//import briefcase from "../../assets/img/Frame 43713.png";
//import roi from "../../assets/img/Frame 43714.png";
//import happy from "../../assets/img/happy.png";
import house from "../../assets/img/house1.png";
import heart from "../../assets/img/Vector.png";
import star from "../../assets/img/Star.png";
import rightArrow from "../../assets/img/right-arrow.png";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "../../utils/helperFunctions";
import { Pagination, message } from "antd";
import makeAPICallForm from "../../utils/apiUtilsForm";
import {
  getCurrentInvestmentSelector,
  GetInvestmentUser,
} from "../../services/slices/getInvestment";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

// const statusOptions = [
//   { value: "yes", text: "Yes" },
//   { value: "no", text: "No" },
//   { value: "investment", text: "Investment Type" },
// ];

// const loactionOptions = [
//   { value: "yes", text: "Yes" },
//   { value: "no", text: "No" },
//   { value: "location", text: "Location" },
// ];

const baseURL = process.env.REACT_APP_API_ENDPOINT;

export default function AllInvestment() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // use the hook and selector
  const { loading, investment } = useSelector(getCurrentInvestmentSelector);
  // const [status, setStatus] = useState("investment");
  // const [location, setLocation] = useState("location");
  const [price, setPrice] = useState();
  const [current, setCurrent] = useState(1);
  const [loadingNow, setLoadingNow] = useState(false);

  // const handleChange = (e) => {
  //   setStatus(e.target.value);
  // };

  // const handleChangeLocation = (e) => {
  //   setLocation(e.target.value);
  // };

  useEffect(() => {
    dispatch(GetInvestmentUser({}));
  }, [dispatch]);

  // console.log(investment, "test");

  const toSingleInvestment = (item) => {
    navigate(`/all/${item?.slug}`, { state: item });
  };

  const handlePageChange = (page) => {
    setCurrent(page);
    dispatch(
      GetInvestmentUser({
        params: { page: page },
      })
    );
  };

  function handleSearch() {
    setLoadingNow(true);
    return makeAPICallForm({
      path: `/investment/investment/?s=${price}`,
      method: "GET",
    })
      .then((res) => {
        navigate("/search", { state: { res, price } });
        setLoadingNow(false);
        setPrice("");
      })
      .catch((err) => {
        message.error(err.message, 5);
        setLoadingNow(false);
      });
  }

  return (
    <div>
      <Outlet />
      {pathname === "/all" && (
        <div>
          <NavBar />
          <section className="hero-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="hero-title">Investments</h1>
                  <p className="hero-sub-title">
                    Get high ROI on short & long term investments
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="search-section pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="filter-card">
                    <div className="row">
                      {/* <div className="col-md-4">
                        <Select
                          name="status"
                          defaultValue={status}
                          onChange={handleChange}
                          options={statusOptions}
                        />
                      </div>
                      <div className="col-md-3">
                        <Select
                          name="location"
                          defaultValue={location}
                          onChange={handleChangeLocation}
                          options={loactionOptions}
                        />
                      </div> */}
                      <div className="col-md-9">
                        <InputField
                          type="text"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          name="price"
                          placeholder="Search for an investment"
                        />
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="button"
                          onClick={handleSearch}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            height: "45px",
                          }}
                          disabled={!price}
                        >
                          {loadingNow ? "Searching..." : "Search"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="listings pb-5 list-back-single">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <h1 className="mb-3">Our Listed investment Products</h1> */}
                      <p className="mb-4">
                        At Yieldroom Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel ut habitant odio at ut sodales quis
                        venenatis. Habitant porttitor neque, ultrices posuere
                        atama odio. Nisl, ignlisruj pellentesque aliquam, augue
                        et. Consectetur nisl tellus lectus nibh ac ultricies
                        egestas at elit. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel ut habitant odior at ut sodales
                        quis venenatis. Habitant porttitor neque, at
                      </p>
                      <div>
                        {loading ? (
                          "Loading..."
                        ) : (
                          <div>
                            <div className="row mb-3">
                              {investment?.results?.map((item, index) => (
                                <div className="col-md-6" key={index}>
                                  <div className="listing-card">
                                    <div className="img-listing">
                                      <img
                                        src={
                                          item?.image.length > 0
                                            ? `${baseURL}${item?.image[0].gallery_url}`
                                            : house
                                        }
                                        alt="listing"
                                      />
                                    </div>
                                    <div className="p-4">
                                      <div className="listing-details p-4">
                                        <div className="d-flex align-items-center justify-content-around">
                                          <div>&nbsp;</div>
                                          <div className="d-flex">
                                            <h2
                                              className={
                                                "open-listing mb-3 " +
                                                (item?.is_closed
                                                  ? "orange"
                                                  : "green")
                                              }
                                            >
                                              {item?.is_closed
                                                ? "Closed Investment"
                                                : "Open Investment"}
                                            </h2>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={star}
                                              className="rating-img"
                                              alt="star"
                                            />
                                            <h2 className="rating">4.5</h2>
                                          </div>
                                        </div>
                                        <h2 className="listing-name my-3">
                                          {item?.room?.name} -{" "}
                                          <span>{item?.name}</span>
                                        </h2>
                                        <div className="align-items-center justify-content-around">
                                          <div className="row mb-3">
                                            <div className="col-md-6 align-items-left">
                                              <h4 className="listing-properties">
                                                Start
                                              </h4>
                                              <small>
                                                {formatDate(item?.start_date)}
                                              </small>
                                            </div>
                                            <div className="col-md-6 align-items-right">
                                              <h4 className="listing-properties">
                                                End
                                              </h4>
                                              <small>
                                                {formatDate(item?.end_date)}
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                        <h2 className="estimate my-4">
                                          <span>Status</span>
                                        </h2>
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <h2 className="tenor mb-2">
                                              Title
                                            </h2>
                                            <h2
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                              className="range"
                                            >
                                              {item?.title_status}
                                            </h2>
                                          </div>
                                          <div>
                                            <h2 className="tenor mb-2">
                                              Construction
                                            </h2>
                                            <h2
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                              className="range text-center"
                                            >
                                              {item?.construction_status}
                                            </h2>
                                          </div>
                                          <div>
                                            <h2 className="tenor mb-2">
                                              Project
                                            </h2>
                                            <h2
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                              className="range text-end"
                                            >
                                              {item?.project_status}
                                            </h2>
                                          </div>
                                        </div>
                                        <div className="text-center m-3">
                                          <Button
                                            type="button"
                                            style={{
                                              background: "transparent",
                                              color: "#ED8428",
                                              outline: 0,
                                              border: 0,
                                            }}
                                            onClick={() => {
                                              toSingleInvestment(item);
                                            }}
                                          >
                                            View Investment
                                          </Button>
                                        </div>
                                      </div>
                                    </div>

                                    <img
                                      src={heart}
                                      alt="favourite"
                                      className="favourite"
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="d-flex justify-content-center">
                              <Pagination
                                onChange={handlePageChange}
                                defaultCurrent={current}
                                total={investment?.count}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="start-investment">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="row">
                    <div className="col-md-6">
                      <h1 className="mb-2">
                        Grow your investment with Yieldroom
                      </h1>
                      <p className="mb-5">Investment for Everyone!</p>
                      <Button
                        type="button"
                        style={{
                          background: "white",
                          color: "#ED8428",
                          borderRadius: "10px",
                        }}
                      >
                        Start Investing Now &nbsp;{" "}
                        <img src={rightArrow} alt="btn arrow" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </div>
  );
}
