import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import { Table, message, Drawer, Input, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import makeAPICall from "../../../utils/apiUtils";
import backbtn from "../../../assets/img/Back.svg";
import ticket from "../../../assets/img/Ticket.png";
import disconnect from "../../../assets/img/disconnect.png";
import editIcon from "../../../assets/img/edit_icon.png";
import moment from "moment";
import Button from "../../../components/Button";
import { formatMoney } from "../../../utils/helperFunctions";
import "./investor.css";
import TextArea from "../../../components/TextArea";

export default function Investors() {
  const navigate = useNavigate();
  const [views, setViews] = useState(false);
  const [fistView, setFirstView] = useState(false);
  const [investmentView, setInvestmentView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anotherLoading, setAnotherLoading] = useState(false);
  // eslint-disable-next-line
  const [modalLoading, setModalLoading] = useState(false);
  const [userTable, setUserTable] = useState();
  const [singleUserTable, setSingleUserTable] = useState();
  const [current, setCurrent] = useState(1);
  const [currentTwo, setCurrentTwo] = useState(1);
  const [commentInput, setCommentInput] = useState("");
  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });
  const [isLoadingClose, setIsLoadingClose] = useState({
    loading: false,
    key: 0,
  });
  const [open, setOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    investment_price: "",
  });
  const [immediate, setImmediate] = useState();
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (commentInput === "") {
      message.error("Please enter a comment");
    } else {
      const data = {
        comment: commentInput,
        is_closed: immediate?.is_closed ? "True" : "False",
      };
      setModalLoading(true);
      return makeAPICall({
        path: `/comment/admin/create/${immediate.id}`,
        method: "POST",
        payload: data,
      })
        .then((res) => {
          // console.log(res);
          setIsLoading({ loading: false, key: 0 });
          setModalLoading(false);
          setIsModalOpen(false);
          //showInvestment();
        })
        .catch((err) => {
          setIsLoading({ loading: false, key: 0 });
          setModalLoading(false);
          // console.log(err);
          message.error(err.message, 5);
        });
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = (row) => {
    setInputValues({
      investment_price: row?.amount,
    });
    setImmediate(row);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  // function getUsers() {
  //   setLoading(true);
  //   return makeAPICall({
  //     path: "/investor/admin/list/investors/",
  //     method: "GET",
  //     params: null,
  //   })
  //     .then((res) => {
  //       // console.log(res);
  //       setLoading(false);
  //       setUserTable(res);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       message.error(err.message, 5);
  //     });
  // }

  function getInvestors() {
    setLoading(true);
    return makeAPICall({
      path: "/investor/admin/investors",
      method: "GET",
      params: null,
    })
      .then((res) => {
        // console.log(res, 'get investors');
        setLoading(false);
        setUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  }

  useEffect(() => {
    // getUsers();
    getInvestors();
  }, []);

  useEffect(() => {
    if (views === true) {
      navigate("/admin");
    }
  }, [views, navigate]);

  const showView = () => {
    setViews(true);
  };

  const confirmPayment = (id) => {
    const data = {
      is_approved: "True",
    };
    setIsLoading({ loading: true, key: id });
    return makeAPICall({
      path: `/investor/approve/${id}`,
      payload: data,
      method: "PATCH",
    })
      .then((res) => {
        setIsLoading({ loading: false, key: 0 });
        showInvestment();
      })
      .catch((err) => {
        setIsLoading({ loading: false, key: 0 });
        message.error(err.message, 5);
      });
  };

  const closeInvest = (id, closed) => {
    if (closed) {
      const data = {
        is_closed: "False",
      };
      setIsLoadingClose({ loading: true, key: id });
      return makeAPICall({
        path: `/investor/close/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoadingClose({ loading: false, key: 0 });
          showInvestment();
        })
        .catch((err) => {
          setIsLoadingClose({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    } else {
      const data = {
        is_closed: "True",
      };
      setIsLoadingClose({ loading: true, key: id });
      return makeAPICall({
        path: `/investor/close/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoadingClose({ loading: false, key: 0 });
          showInvestment();
        })
        .catch((err) => {
          setIsLoadingClose({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    }
  };

  function showInvestment(row) {
    // console.log(row);
    // setInvestmentView(true);
    setAnotherLoading(true);
    setFirstView(true);
    setImmediate(row);
    return makeAPICall({
      path: `/investor/admin/investor/${row.id}`,
      method: "GET",
      params: null,
    })
      .then((res) => {
        // console.log(res, "testststs");
        setAnotherLoading(false);
        setSingleUserTable(res);
      })
      .catch((err) => {
        setAnotherLoading(false);
        message.error(err.message, 5);
      });
  }

  const showInvestmentTwo = (row) => {
    // console.log(row);
    setInvestmentView(true);
    setImmediate(row);
  };

  const hideView = () => {
    setInvestmentView(false);
  };

  const hideViewTwo = () => {
    setFirstView(false);
  };

  const columnsTwo = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("L"),
    },
    {
      title: "Investor",
      dataIndex: "firstname",
      key: "firstname",
      render: (firstname, row) => (
        <p style={{ textTransform: "capitalize" }}>
          {firstname}&nbsp; {row?.lastname}
        </p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Total Active Investment",
      dataIndex: "totalactiveinvestment",
      key: "totalactiveinvestment",
    },
    {
      title: "Total Closed Investment",
      dataIndex: "totalclosedinvestment",
      key: "totalclosedinvestment",
    },
    {
      title: "Total Pending Investment",
      dataIndex: "totalpendinginvestment",
      key: "totalpendinginvestment",
    },
    {
      title: "Total Comment",
      dataIndex: "totalcomment",
      key: "totalcomment",
    },
    {
      title: "Investments",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Button
          type="button"
          onClick={() => showInvestment(row)}
          style={{ backgroundColor: "#EA8745" }}
        >
          See Investments
        </Button>
      ),
    },
  ];

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("L"),
    },
    {
      title: "Investor",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>
          {row?.investor?.firstname}&nbsp; {row?.investor?.lastname}
        </p>
      ),
    },
    {
      title: "Amount Invested",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <p>N{formatMoney(amount)}</p>,
    },
    {
      title: "Investment Target",
      dataIndex: "id",
      key: "id",
      render: (id, row) => <p>N{formatMoney(row?.investment?.amount)}</p>,
    },
    {
      title: "Rate",
      dataIndex: "id",
      key: "id",
      render: (id, row) => <p>{row?.investment?.roi}</p>,
    },
    {
      title: "Tenor",
      dataIndex: "id",
      key: "id",
      render: (id, row) => <p>{row?.investment?.period?.period}</p>,
    },
    {
      title: "Investments",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Button
          type="button"
          onClick={() => showInvestmentTwo(row)}
          style={{ backgroundColor: "#EA8745" }}
        >
          See Investment Info
        </Button>
      ),
    },
    {
      title: "Action",
      dataIndex: "is_approved",
      key: "is_approved",
      render: (is_approved, row) => {
        if (is_approved) {
          return (
            <div className="d-flex justify-content-between">
              <span
                style={{
                  backgroundColor: "#E8FFEE",
                  borderRadius: "20px",
                  padding: "8px",
                  color: "#14B941",
                }}
              >
                Payment Confirmed
              </span>
              <Button
                type="button"
                onClick={() => showDrawer(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                Edit
              </Button>
            </div>
          );
        } else {
          return (
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                onClick={() => confirmPayment(row.id)}
                style={{ backgroundColor: "#14B941" }}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Confirm Payment"}
              </Button>
              <Button
                type="button"
                onClick={() => showDrawer(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                Edit
              </Button>
            </div>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "is_closed",
      key: "is_closed",
      render: (is_closed, row) => {
        if (is_closed) {
          return (
            <Button
              type="button"
              onClick={() => closeInvest(row.id, is_closed)}
              style={{ backgroundColor: "#EA8745" }}
            >
              {isLoadingClose.loading && isLoadingClose.key === row.id
                ? "Loading..."
                : "Keep Investment Open"}
            </Button>
          );
        } else {
          return (
            <Button
              type="button"
              onClick={() => closeInvest(row.id, is_closed)}
              style={{ backgroundColor: "#14B941" }}
            >
              {isLoadingClose.loading && isLoadingClose.key === row.id
                ? "Loading..."
                : "Close Investment"}
            </Button>
          );
        }
      },
    },
  ];

  const columnsComment = [
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: "400px",
    },
    {
      title: "Responded By",
      dataIndex: "responded_by",
      key: "responded_by",
    },

    {
      title: "Status",
      dataIndex: "is_closed",
      key: "is_closed",
      render: (is_closed) => {
        if (is_closed) {
          return (
            <span
              style={{
                backgroundColor: "#EA8745",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              Closed
            </span>
          );
        } else {
          return (
            <span
              style={{
                backgroundColor: "#14B941",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              Open
            </span>
          );
        }
      },
    },
  ];

  function getData(item) {
    // console.log(item, 'sjsjs')
    setCurrent(item.current);
    return makeAPICall({
      path: "/investor/admin/investors",
      method: "GET",
      params: { page: item.current },
    })
      .then((res) => {
        setLoading(false);
        setUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  }

  function getDataTwo(item, itemtwo) {
    console.log(itemtwo, "sjsjs");
    setCurrentTwo(item.current);
    return makeAPICall({
      path: `/investor/admin/investor/${itemtwo}`,
      method: "GET",
      params: { page: item.current },
    })
      .then((res) => {
        setLoading(false);
        setSingleUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  }

  const newInvestmentButton = () => {
    const data = {
      amount: inputValues.investment_price,
      investment: immediate.investment.id,
      investor: immediate.investor.id,
      is_closed: immediate.is_closed ? "True" : "False",
      is_approved: immediate.is_approved ? "True" : "False",
    };
    setLoadingEdit(true);
    return makeAPICall({
      path: `/investor/admin/update/${immediate.id}`,
      payload: data,
      method: "PUT",
    })
      .then((res) => {
        setLoadingEdit(false);
        setOpen(false);
        message.success("Your investment was updated successfully", 5);
        showInvestment();
      })
      .catch((err) => {
        setLoadingEdit(false);
        message.error(err.message, 5);
      });
  };

  return (
    <AdminBackBone title="Investors" onClick={showView}>
      {investmentView ? (
        <div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div
                className="d-flex align-items-center"
                onClick={hideView}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={backbtn}
                  alt="go back"
                  style={{ marginRight: "10px" }}
                />
                <h1 className="back-text">Investor’s Profile</h1>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-3 p-4">
            <div className="row">
              <div className="col-md-3">
                <Button
                  type="button"
                  onClick={showModal}
                  style={{
                    backgroundColor: "transparent",
                    color: "#ED8428",
                    border: "1px solid #ed8428",
                  }}
                >
                  <img
                    src={editIcon}
                    alt="go back"
                    style={{ marginRight: "10px", width: "15px" }}
                  />{" "}
                  Add comment
                </Button>
              </div>
              <div className="col-md-6 major-invest">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h1 style={{ textTransform: "capitalize" }}>
                      <span className="orange">
                        {immediate?.investor?.firstname},
                      </span>{" "}
                      {immediate?.investor?.lastname}
                    </h1>
                  </div>
                  <div>
                    <h2>{immediate?.investor?.email}</h2>
                    <p>Email</p>
                  </div>
                  <div>
                    <h2>{immediate?.investor?.phone}</h2>
                    <p>Phone Number</p>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h2 style={{ textTransform: "capitalize" }}>
                      {immediate?.investment?.room?.name}
                    </h2>
                    <p>Business Interest</p>
                  </div>
                  <div>
                    <h2>{immediate?.investment?.risk?.risk}</h2>
                    <p>Investment Appetite</p>
                  </div>
                  <div>
                    <h3 className={immediate?.is_closed ? "orange" : "green"}>
                      {immediate?.is_closed ? "Inactive" : "Active"}
                    </h3>
                    <p>Status</p>
                  </div>
                </div>
                <br />
                <br />
                <div className="d-flex align-items-center justify-content-around">
                  <div className="d-flex align-items-center">
                    <img
                      src={ticket}
                      alt="go back"
                      style={{
                        marginRight: "10px",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                    <span className="play-dis">Offer Discount Promo</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={disconnect}
                      alt="go back"
                      style={{ marginRight: "10px", width: "15px" }}
                    />
                    <span className="play-dis" style={{ color: "#CC2E2E" }}>
                      Deactivate Investor
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="investor-invest-bg p-4">
                  <p className="mb-3">Total Investment Value</p>
                  <h1 className="mb-5">₦ {formatMoney(immediate?.amount)}</h1>
                  <p className="mb-3">Investment Count</p>
                  <h2>{immediate?.investor?.totalinvestment}</h2>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                {immediate?.comment?.length > 0 ? (
                  <Table
                    columns={columnsComment}
                    dataSource={immediate?.comment}
                    rowKey="id"
                    scroll={{ x: "max-content" }}
                    pagination={false}
                  />
                ) : (
                  "No Comments"
                )}
              </div>
            </div>
          </div>
        </div>
      ) : fistView ? (
        <div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div
                className="d-flex align-items-center"
                onClick={hideViewTwo}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={backbtn}
                  alt="go back"
                  style={{ marginRight: "10px" }}
                />
                <h1 className="back-text">Single Investor Investments</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-records">
                {anotherLoading ? (
                  "Loading..."
                ) : (
                  <Table
                    columns={columns}
                    dataSource={singleUserTable?.results}
                    rowKey="id"
                    pagination={{
                      showSizeChanger: true,
                      total: singleUserTable?.count,
                      current: currentTwo,
                    }}
                    onChange={(pagination, rowKey) => {
                      getDataTwo(pagination, rowKey);
                    }}
                    scroll={{ x: "max-content" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="table-records">
              <div className="d-flex justify-content-between mb-2">
                <h1>All Investors</h1>
              </div>
              {loading ? (
                "Loading..."
              ) : (
                <Table
                  columns={columnsTwo}
                  dataSource={userTable?.results}
                  rowKey="id"
                  pagination={{
                    showSizeChanger: true,
                    total: userTable?.count,
                    current: current,
                  }}
                  onChange={(pagination) => {
                    getData(pagination);
                  }}
                  scroll={{ x: "max-content" }}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <Drawer
        title="Edit Investment"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div className="row investment-form-fields">
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Investment Price</h2>
              <Input
                prefix="₦"
                onChange={handleChangeInput}
                value={inputValues.investment_price}
                name="investment_price"
                type="number"
                placeholder="0,00"
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <Button
              type="button"
              onClick={newInvestmentButton}
              disabled={!inputValues.investment_price}
            >
              {loadingEdit ? "Loading..." : "Update Investment"}
            </Button>
          </div>
        </div>
      </Drawer>
      <Modal
        title="Add Comment"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="investment-form-fields">
          <h2>Add a comment</h2>
          <TextArea
            onChange={(e) => setCommentInput(e.target.value)}
            value={commentInput}
            name="description"
            rows="7"
            placeholder="A short paragraph describing the details of this investment."
          />
        </div>
      </Modal>
    </AdminBackBone>
  );
}
