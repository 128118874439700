import { createSlice } from "@reduxjs/toolkit";
import makeAPICall from "../../utils/apiUtils";
import { message } from "antd";

const initialState = {
  loading: false,
  hasErrors: null,
  investment: {},
};

export const currentIssuerInvestmentSlice = createSlice({
  name: "currentIssuerInvestment",
  initialState,
  reducers: {
    getApp: (state = initialState) => {
      state.loading = true;
    },
    getAppSuccess: (state, { payload }) => {
      state.investment = payload;
      state.loading = false;
    },
    getAppFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

// Three actions generated from the slice
const { getApp, getAppSuccess, getAppFailure } =
  currentIssuerInvestmentSlice.actions;

// A selector
export const getCurrentIssuerInvestmentSelector = (state) =>
  state.currentIssuerInvestment;

// The reducer
export default currentIssuerInvestmentSlice.reducer;

// api call action
export const GetIssuerInvestmentUser =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(getApp());
    return makeAPICall({
      path: "/investment/investment/issuer/",
      method: "GET",
      params,
    })
      .then((res) => {
        dispatch(getAppSuccess(res));
      })
      .catch((err) => {
        message.error(err.message, 5);
        dispatch(getAppFailure(err));
      });
  };
