import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import transactionIcon from "../../../assets/img/Activity.svg";
import investorsIcon from "../../../assets/img/User.svg";
import growIcon from "../../../assets/img/grow.svg";
import cartIcon from "../../../assets/img/cart.svg";
// import editIcon from "../../../assets/img/Edit.svg";
import chartIcon from "../../../assets/img/Chart.svg";
import reduceIcon from "../../../assets/img/reduce.svg";
import Button from "../../../components/Button";
import { Table, message, Drawer, Input } from "antd";
// import { investments } from "./table";
import { formatMoney } from "../../../utils/helperFunctions";
import moment from "moment";
import UploadInvestment from "./uploadInvestment";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentInvestmentSelector,
  GetInvestmentUser,
} from "../../../services/slices/getInvestment";
import makeAPICall from "../../../utils/apiUtils";
import makeAPICallForm from "../../../utils/apiUtilsForm";
import InputField from "../../../components/InputField";

export default function AdminDashboard() {
  const dispatch = useDispatch();
  // use the hook and selector
  const { loading, investment } = useSelector(getCurrentInvestmentSelector);
  const [views, setViews] = useState(false);
  const [current, setCurrent] = useState(1);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [isLoadingClose, setIsLoadingClose] = useState({
    loading: false,
    key: 0,
  });
  const [open, setOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    investment_name: "",
    investment_price: "",
    investment_location: "",
    investment_description: "",
    investment_features: "",
    investment_return: "",
    investment_annual: "",
  });
  const [period, setPeriod] = useState();
  const [category, setCategory] = useState();
  const [investPeriod, setInvestPeriod] = useState("");
  const [investCategory, setInvestCategory] = useState("");
  const [risk, setRisk] = useState();
  const [investRisk, setInvestRisk] = useState("");
  const [immediate, setImmediate] = useState();
  const [totalInvestmentAmount, setTotalInvestmentAmount] = useState(0)
  const [totalVerifiedInvestment, setTotalVerifiedInvestment] = useState(0)
  const [totalNotVerifiedInvestment, setTotalNotVerifiedInvestment] = useState(0)
  const [totalVerifiedInvestmentAmount, setTotalVerifiedInvestmentAmount] = useState(0)
  const [totalUnverifiedInvestmentAmount, setTotalUnverifiedInvestmentAmount] = useState(0)

  const showDrawer = (row) => {
    setInputValues({
      investment_name: row?.name,
      investment_price: row?.amount,
      investment_location: row?.location,
      investment_description: row?.description,
      investment_features: row?.features,
      investment_return: row?.roi,
      investment_annual: row?.annualized,
    });
    setInvestCategory(row?.room?.id);
    setInvestPeriod(row?.period?.id);
    setInvestRisk(row?.risk?.id);
    // console.log(row);
    setImmediate(row);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const showView = () => {
    setViews(true);
  };

  const hideView = () => {
    setViews(false);
  };

  const handlePeriodChange = (e) => {
    setInvestPeriod(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setInvestCategory(e.target.value);
  };

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const handleRiskChange = (e) => {
    setInvestRisk(e.target.value);
  };

  const approve = (id, verified) => {
    if (verified) {
      const data = {
        is_verified: "False",
      };
      setIsLoading({ loading: true, key: id });
      return makeAPICall({
        path: `/investment/approve/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoading({ loading: false, key: 0 });
          dispatch(GetInvestmentUser({}));
        })
        .catch((err) => {
          setIsLoading({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    } else {
      const data = {
        is_verified: "True",
      };
      setIsLoading({ loading: true, key: id });
      return makeAPICall({
        path: `/investment/approve/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoading({ loading: false, key: 0 });
          dispatch(GetInvestmentUser({}));
        })
        .catch((err) => {
          setIsLoading({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    }
  };

  const closeInvestment = (id, closed) => {
    if (closed) {
      const data = {
        is_closed: "False",
      };
      setIsLoadingClose({ loading: true, key: id });
      return makeAPICall({
        path: `/investment/close/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoadingClose({ loading: false, key: 0 });
          dispatch(GetInvestmentUser({}));
        })
        .catch((err) => {
          setIsLoadingClose({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    } else {
      const data = {
        is_closed: "True",
      };
      setIsLoadingClose({ loading: true, key: id });
      return makeAPICall({
        path: `/investment/close/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoadingClose({ loading: false, key: 0 });
          dispatch(GetInvestmentUser({}));
        })
        .catch((err) => {
          setIsLoadingClose({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("L"),
    },
    {
      title: "Investment Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "id",
      key: "id",
      render: (room, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.room?.name}</p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <p>N{formatMoney(amount)}</p>,
    },
    {
      title: "Issuer Name",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p>
          {row?.owner?.firstname}&nbsp; {row?.owner?.lastname}
        </p>
      ),
    },
    {
      title: "Return",
      dataIndex: "roi",
      key: "roi",
    },
    {
      title: "Tenor",
      dataIndex: "id",
      key: "id",
      render: (period, row) => <p>{row?.period?.period}</p>,
    },
    {
      title: "Action",
      dataIndex: "is_verified",
      key: "is_verified",
      width: "100px",
      render: (is_verified, row) => {
        if (is_verified) {
          return (
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                onClick={() => approve(row.id, is_verified)}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Disapprove"}
              </Button>
              <Button
                type="button"
                onClick={() => showDrawer(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                Edit
              </Button>
            </div>
          );
        } else {
          return (
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                onClick={() => approve(row.id, is_verified)}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Approve"}
              </Button>
              <Button
                type="button"
                onClick={() => showDrawer(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                Edit
              </Button>
            </div>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "is_closed",
      key: "is_closed",
      render: (is_closed, row) => {
        if (is_closed) {
          return (
            <Button
              type="button"
              onClick={() => closeInvestment(row.id, is_closed)}
              style={{ backgroundColor: "#EA8745" }}
            >
              {isLoadingClose.loading && isLoadingClose.key === row.id
                ? "Loading..."
                : "Close"}
            </Button>
          );
        } else {
          return (
            <Button
              type="button"
              onClick={() => closeInvestment(row.id, is_closed)}
              style={{ backgroundColor: "#14B941" }}
            >
              {isLoadingClose.loading && isLoadingClose.key === row.id
                ? "Loading..."
                : "Open"}
            </Button>
          );
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(GetInvestmentUser({}));
  }, [dispatch]);

  function getData(item) {
    // console.log(item, 'sjsjs')
    setCurrent(item.current);
    dispatch(
      GetInvestmentUser({
        params: { page: item.current },
      })
    );
  }

  useEffect(() => {
    const investmentCategory = () => {
      return makeAPICall({
        path: "/investment/room/all/",
        method: "GET",
      })
        .then((res) => {
          setCategory(res.results);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const investmentAppetite = () => {
      return makeAPICall({
        path: "/investor/risk/all",
        method: "GET",
      })
        .then((res) => {
          setRisk(res.results);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const investmentPeriod = () => {
      return makeAPICall({
        path: "/investor/period/all",
        method: "GET",
      })
        .then((res) => {
          setPeriod(res.results);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const totalInvestAmount = () => {
      return makeAPICall({
        path: "/investment/total/investment/",
        method: "GET",
      })
        .then((res) => {
          // console.log(res)
          setTotalInvestmentAmount(res.amount)
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const totalVerifiedInvestment = () => {
      return makeAPICall({
        path: "/investment/verified/",
        method: "GET",
      })
        .then((res) => {
          // console.log(res)
          setTotalVerifiedInvestment(res.investments)
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const totalNotVerifiedInvestment = () => {
      return makeAPICall({
        path: "/investment/not-verified/",
        method: "GET",
      })
        .then((res) => {
          // console.log(res)
          setTotalNotVerifiedInvestment(res.investments)
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const totalInvestAmountVerified = () => {
      return makeAPICall({
        path: "/investment/total/investment/verified/",
        method: "GET",
      })
        .then((res) => {
          // console.log(res)
          setTotalVerifiedInvestmentAmount(res.amount)
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const totalInvestAmountUnverified = () => {
      return makeAPICall({
        path: "/investment/total/investment/not-verified/",
        method: "GET",
      })
        .then((res) => {
          // console.log(res)
          setTotalUnverifiedInvestmentAmount(res.amount)
        })
        .catch((err) => {
          console.log(err);
        });
    };

    totalVerifiedInvestment()
    totalInvestAmount();
    totalNotVerifiedInvestment()
    totalInvestAmountVerified()
    totalInvestAmountUnverified()
    investmentPeriod();
    investmentAppetite();
    investmentCategory();
  }, []);

  const newInvestmentButton = () => {
    const formData = new FormData();
    // formData.append("id", immediate.id);
    formData.append("name", inputValues.investment_name);
    formData.append("description", inputValues.investment_description);
    formData.append("features", inputValues.investment_features);
    formData.append("room", investCategory);
    formData.append("period", investPeriod);
    formData.append("roi", inputValues.investment_return);
    formData.append("annualized", inputValues.investment_annual);
    formData.append("risk", investRisk);
    formData.append("amount", inputValues.investment_price);
    formData.append("location", inputValues.investment_location);
    formData.append("is_verified", immediate.is_verified ? "True" : "False");
    formData.append("is_closed", immediate.is_closed ? "True" : "False");
    setLoadingEdit(true);
    return makeAPICallForm({
      path: `/investment/invest/${immediate.id}`,
      payload: formData,
      method: "PUT",
    })
      .then((res) => {
        setLoadingEdit(false);
        setOpen(false);
        message.success("Your investment was updated successfully", 5);
        dispatch(GetInvestmentUser({}));
      })
      .catch((err) => {
        setLoadingEdit(false);
        console.log(err);
      });
  };

  return (
    <AdminBackBone title="Dashboard" onClick={showView}>
      {views ? (
        <UploadInvestment close={hideView} />
      ) : (
        <div>
          {" "}
          <div className="row row-grid">
            <div className="col-md-3">
              <div className="analytics-tab">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="analytics-icon"
                    style={{ backgroundColor: "rgba(234, 67, 54, 0.1)" }}
                  >
                    <img src={transactionIcon} alt="analytics" />
                  </div>
                  <h1>Total Investment Amount</h1>
                </div>
                <div className="d-flex align-items-center justify-content-between analytics-light">
                  <h2>₦{formatMoney(totalInvestmentAmount)}</h2>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="green-bg">
                      <img src={growIcon} alt="analytics up" />
                    </div>
                    <h3 className="green">1.6%</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="analytics-tab">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="analytics-icon"
                    style={{ backgroundColor: "#FBECDB" }}
                  >
                    <img src={investorsIcon} alt="analytics" />
                  </div>
                  <h1>Total Verified Investment</h1>
                </div>
                <div className="d-flex align-items-center justify-content-between analytics-light">
                  <h2>{totalVerifiedInvestment}</h2>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="orange-bg">
                      <img src={reduceIcon} alt="analytics up" />
                    </div>
                    <h3>1.6%</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="analytics-tab">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="analytics-icon"
                    style={{ backgroundColor: "#E8F5F1" }}
                  >
                    <img src={cartIcon} alt="analytics" />
                  </div>
                  <h1>Total Verified Investment</h1>
                </div>
                <div className="d-flex align-items-center justify-content-between analytics-light">
                  <h2>{totalNotVerifiedInvestment}</h2>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="orange-bg">
                      <img src={reduceIcon} alt="analytics up" />
                    </div>
                    <h3>1.6%</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="analytics-tab">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="analytics-icon"
                    style={{ backgroundColor: "#E0EEFF" }}
                  >
                    <img src={chartIcon} alt="analytics" />
                  </div>
                  <h1>Total Verified Investment Amount</h1>
                </div>
                <div className="d-flex align-items-center justify-content-between analytics-light">
                  <h2>₦ {formatMoney(totalVerifiedInvestmentAmount)}</h2>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="green-bg">
                      <img src={growIcon} alt="analytics up" />
                    </div>
                    <h3 className="green">1.6%</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row row-grid">
          <div className="col-md-3">
              <div className="analytics-tab">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="analytics-icon"
                    style={{ backgroundColor: "#E0EEFF" }}
                  >
                    <img src={chartIcon} alt="analytics" />
                  </div>
                  <h1>Total Unverified Investment Amount</h1>
                </div>
                <div className="d-flex align-items-center justify-content-between analytics-light">
                  <h2>₦ {formatMoney(totalUnverifiedInvestmentAmount)}</h2>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="green-bg">
                      <img src={growIcon} alt="analytics up" />
                    </div>
                    <h3 className="green">1.6%</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-grid">
            <div className="col-md-9"></div>
            <div className="col-md-3"></div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="table-records">
                <div className="d-flex justify-content-between mb-2">
                  <h1>Recent Investments</h1>
                  {/* <p>See more</p> */}
                </div>
                {loading ? (
                  "Loading..."
                ) : (
                  <Table
                    columns={columns}
                    dataSource={investment?.results}
                    rowKey="id"
                    pagination={{
                      showSizeChanger: true,
                      total: investment?.count,
                      current: current,
                    }}
                    onChange={(pagination) => {
                      getData(pagination);
                    }}
                    scroll={{ x: "max-content" }}
                  />
                )}
              </div>
            </div>
          </div>{" "}
        </div>
      )}
      <Drawer
        title="Edit Investment"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div className="row investment-form-fields">
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Investment Name</h2>
              <InputField
                type="text"
                value={inputValues.investment_name}
                onChange={handleChangeInput}
                name="investment_name"
                placeholder="Give the investment a name"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Investment Price</h2>
              <Input
                prefix="₦"
                onChange={handleChangeInput}
                value={inputValues.investment_price}
                name="investment_price"
                type="number"
                placeholder="0,00"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Category</h2>
              <select
                onChange={handleCategoryChange}
                className="select-area"
                name="investCategory"
                value={investCategory}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                {category?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Location of the Investment</h2>
              <InputField
                type="text"
                value={inputValues.investment_location}
                onChange={handleChangeInput}
                name="investment_location"
                placeholder="Enter investment location"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Associated Return</h2>
              <InputField
                value={inputValues.investment_return}
                onChange={handleChangeInput}
                name="investment_return"
                type="text"
                placeholder="Enter returns e.g. 10%"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Tenor</h2>
              <select
                onChange={handlePeriodChange}
                className="select-area"
                name="investPeriod"
                value={investPeriod}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                {period?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.period}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Description</h2>
              <InputField
                type="text"
                value={inputValues.investment_description}
                onChange={handleChangeInput}
                name="investment_description"
                placeholder="Describe the investment"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Feature List</h2>
              <InputField
                type="text"
                value={inputValues.investment_features}
                onChange={handleChangeInput}
                name="investment_features"
                placeholder="Add features of the investment"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Investment Appetite</h2>
              <select
                onChange={handleRiskChange}
                className="select-area"
                name="investRisk"
                value={investRisk}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                {risk?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.risk}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Annualized</h2>
              <InputField
                value={inputValues.investment_annual}
                onChange={handleChangeInput}
                name="investment_annual"
                type="text"
                placeholder="Enter annual investment e.g. 10%"
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <Button
              type="button"
              onClick={newInvestmentButton}
              disabled={
                !(
                  inputValues.investment_name &&
                  inputValues.investment_price &&
                  inputValues.investment_return &&
                  inputValues.investment_location &&
                  inputValues.investment_features &&
                  inputValues.investment_description &&
                  investPeriod &&
                  investCategory
                )
              }
            >
              {loadingEdit ? "Loading..." : "Update Investment"}
            </Button>
          </div>
        </div>
      </Drawer>
    </AdminBackBone>
  );
}
