import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import "./nav.css";
import Button from "../../components/Button";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLoginSelector } from "../../services/slices/auth/login";
import { Modal } from "antd";
import { logout } from "../../utils/authUtils";

function NavBar() {
  const { user } = useSelector(getLoginSelector);
  const navigate = useNavigate();
  const [menu, setMenu] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleMenu = () => {
    setMenu(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const gotoDash = () => {
    if (user.is_staff === false && user.is_approved === true) {
      navigate("/dashboard");
    } else {
      showModal();
    }
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-nav">
        <div className="container">
          <Link className="navbar-brand d-flex" to="/home">
            <img src={logo} alt="Yield room Logo" className="home-logo" />
          </Link>
          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarsExample09"
            aria-controls="navbarsExample09"
            aria-expanded={!menu ? true : false}
            aria-label="Toggle navigation"
            type="button"
            onClick={toggleMenu}
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </button>
          <div
            className={`${menu ? "collapse" : ""} navbar-collapse w-100`}
            id="navbarsExample09"
          >
            <ul className="nav navbar-nav ml-auto w-100 justify-content-end">
              <li className="nav-item">
                <NavLink
                  className={`nav-link ({ isActive }) => (isActive ? 'active' : 'inactive')`}
                  to="/home"
                >
                  Company
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ({ isActive }) => (isActive ? 'active' : 'inactive')`}
                  to="/all"
                >
                  Invest
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ({ isActive }) => (isActive ? 'active' : 'inactive')`}
                  to="/gallery"
                >
                  Resources
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ({ isActive }) => (isActive ? 'active' : 'inactive')`}
                  to="/blog"
                >
                  FAQs
                </NavLink>
              </li>
              <Button type="button" onClick={gotoDash} style={{marginRight: "5px"}}>
                Go To Dashboard
              </Button>
              <Button type="button" onClick={logout}>
                Logout
              </Button>
            </ul>
          </div>
        </div>
      </nav>
      <Modal
        title={`Hello ${user?.username}`}
        open={isModalOpen}
        onOk={handleOk}
      >
        <p>Account Awaiting Approval By Admin</p>
      </Modal>
    </div>
  );
}

export default NavBar;
