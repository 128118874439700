import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import SearchAll from "../pages/GeneralSearch";
import AllInvestment from "../pages/Investments/allInvestment";
import SingleInvestment from "../pages/Home/singleInvestment";
import Login from "../pages/auth/login";
import Register from "../pages/auth/signup";
import ForgotPassword from "../pages/auth/password";
import ResetPassword from "../pages/auth/password/resetPassword";
import RegisterIssuer from "../pages/auth/issuerSignup";
import Dashboard from "../pages/Overview";
import AdminDashboard from "../pages/admin/admin";
import UserManagement from "../pages/admin/UserManagement";
import Investors from "../pages/admin/Investors";
import Investments from "../pages/Investments";
import ConfirmEmail from "../pages/CallBackPage";
import PrivateRoute from "./privateRoute";
import Congratulations from "../pages/Congratulation";
import IssuerDashboard from "../pages/admin/Issuer";
import SponsorDashboard from "../pages/admin/Sponsor";
import Pending from "../pages/Pending";
import IssuerInvestmentDashboard from "../pages/Issuer/investment";
import ScrollToTop from "../components/TopScroll";
import Profile from "../pages/Investments/profile";

const AllPages = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      {/* <Route exact path="/home" element={<Home />}></Route> */}
      <Route exact path="/" element={<Login />}></Route>
      <Route
        exact
        path="/congratulations"
        element={<Congratulations />}
      ></Route>
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route path="/auth/password-reset" element={<ResetPassword />}>
        <Route path=":id/:token" element={<ResetPassword />} />
      </Route>
      <Route exact path="/pending" element={<Pending />}></Route>
      <Route exact path="/signup" element={<Register />}></Route>
      <Route exact path="/signup/issuer" element={<RegisterIssuer />}></Route>
      <Route path="/confirm" element={<ConfirmEmail />}>
        <Route path=":token" element={<ConfirmEmail />} />
      </Route>
      {/* <Route exact path="/dashboard" element={<Dashboard />}></Route> */}
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      >
        <Route
          path=":name"
          element={
            <PrivateRoute>
              <SingleInvestment />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/all"
        element={
          <PrivateRoute>
            <AllInvestment />
          </PrivateRoute>
        }
      >
        <Route
          path=":name"
          element={
            <PrivateRoute>
              <SingleInvestment />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/search"
        element={
          <PrivateRoute>
            <SearchAll />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <PrivateRoute>
            <IssuerDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/users"
        element={
          <PrivateRoute>
            <UserManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/investors"
        element={
          <PrivateRoute>
            <Investors />
          </PrivateRoute>
        }
      />
      <Route
        path="/investments"
        element={
          <PrivateRoute>
            <Investments />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/issuers"
        element={
          <PrivateRoute>
            <AdminDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/sponsors"
        element={
          <PrivateRoute>
            <SponsorDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/issuer"
        element={
          <PrivateRoute>
            <IssuerInvestmentDashboard />
          </PrivateRoute>
        }
      />
    </Routes>
  </BrowserRouter>
);

export default AllPages;
