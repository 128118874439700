import React, { useState, useEffect } from "react";
import BackBone from "../../components/BackBone";
import transactionIcon from "../../assets/img/Activity.svg";
import investorsIcon from "../../assets/img/User.svg";
import growIcon from "../../assets/img/grow.svg";
//import cartIcon from "../../assets/img/cart.svg";
//import chartIcon from "../../assets/img/Chart.svg";
import reduceIcon from "../../assets/img/reduce.svg";
import "./overview.css";
import { Table, message } from "antd";
import { formatDate } from "../../utils/helperFunctions";
import moment from "moment";
import makeAPICall from "../../utils/apiUtils";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [userTable, setUserTable] = useState();
  const [summary, setSummary] = useState(0);

  useEffect(() => {
    const summary = () => {
      return makeAPICall({
        path: "/investment/investor/summary/",
        method: "GET",
      })
        .then((res) => {
          setSummary(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    summary();
    getInvestments();
  }, []);

  function getInvestments() {
    setLoading(true);
    return makeAPICall({
      path: "/investor/list/investment/",
      method: "GET",
      params: null,
    })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("L"),
    },
    {
      title: "Investment",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.investment?.name}</p>
      ),
    },
    {
      title: "Issuer",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>
          {row?.investment?.owner?.firstname} {row?.investment?.owner?.lastname}
        </p>
      ),
    },
    {
      title: "Volume",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.volume}</p>
      ),
    },
    {
      title: "Location",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>
          {row?.investment?.location}
        </p>
      ),
    },
    {
      title: "Offer Starts",
      dataIndex: "id",
      key: "id",
      render: (id, row) => formatDate(row.investment.start_date),
    },
    {
      title: "Offer Ends",
      dataIndex: "id",
      key: "id",
      render: (id, row) => formatDate(row.investment.end_date),
    },
    {
      title: "Status",
      dataIndex: "is_closed",
      key: "is_closed",
      render: (is_closed) => {
        if (is_closed) {
          return (
            <span
              style={{
                backgroundColor: "#EA8745",
                borderRadius: "20px",
                padding: "8px",
                color: "#ED8428",
              }}
            >
              Closed
            </span>
          );
        } else {
          return (
            <span
              style={{
                backgroundColor: "#E8FFEE",
                borderRadius: "20px",
                padding: "8px",
                color: "#14B941",
              }}
            >
              Open
            </span>
          );
        }
      },
    },
  ];

  return (
    <BackBone title="Dashboard">
      <div>
        {" "}
        <div className="row row-grid">
          <div className="col-md-6">
            <div className="analytics-tab">
              <div className="d-flex align-items-center mb-4">
                <div
                  className="analytics-icon"
                  style={{ backgroundColor: "rgba(234, 67, 54, 0.1)" }}
                >
                  <img src={transactionIcon} alt="analytics" />
                </div>
                <h1>Total Project</h1>
              </div>
              <div className="d-flex align-items-center justify-content-between analytics-light">
                <h2>{summary?.totalInvestments}</h2>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="green-bg">
                    <img src={growIcon} alt="analytics up" />
                  </div>
                  <h3 className="green">%</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="analytics-tab">
              <div className="d-flex align-items-center mb-4">
                <div
                  className="analytics-icon"
                  style={{ backgroundColor: "#FBECDB" }}
                >
                  <img src={investorsIcon} alt="analytics" />
                </div>
                <h1>Total Units</h1>
              </div>
              <div className="d-flex align-items-center justify-content-between analytics-light">
                <h2>{summary?.totalVolume?.volume}</h2>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="orange-bg">
                    <img src={reduceIcon} alt="analytics up" />
                  </div>
                  <h3>%</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row row-grid">
          <div className="col-md-9"></div>
          <div className="col-md-3"></div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <div className="table-records">
              <div className="d-flex justify-content-between mb-2">
                <h1>Recent Investments</h1>
                <Link to="/investments">
                  <p>See more</p>
                </Link>
              </div>
              {loading ? (
                "Loading..."
              ) : (
                <Table
                  columns={columns}
                  rowKey="id"
                  pagination={false}
                  dataSource={userTable?.results.slice(0, 3)}
                  scroll={{ x: "max-content" }}
                />
              )}
            </div>
          </div>
        </div>{" "}
      </div>
    </BackBone>
  );
}
