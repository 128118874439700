import React, { useState, useEffect } from "react";
import BackBone from "../../components/BackBone";
import { Table, message, Modal, Input } from "antd";
import { formatMoney, formatDate } from "../../utils/helperFunctions";
import moment from "moment";
import makeAPICall from "../../utils/apiUtils";
import backbtn from "../../assets/img/Back.svg";
import TextArea from "../../components/TextArea";
import editIcon from "../../assets/img/edit_icon.png";
import disconnect from "../../assets/img/disconnect.png";
import ticket from "../../assets/img/Ticket.png";
import Button from "../../components/Button";

const { Search } = Input;

export default function Investments() {
  const [loading, setLoading] = useState(false);
  const [userTable, setUserTable] = useState();
  const [investmentView, setInvestmentView] = useState(false);
  const [current, setCurrent] = useState(1);
  // Checking buffer
  // remove comment
  //const [modalLoading, setModalLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [immediate, setImmediate] = useState();
  const [commentInput, setCommentInput] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (commentInput === "") {
      message.error("Please enter a comment");
    } else {
      const data = {
        comment: commentInput,
      };
      //setModalLoading(true);
      setIsModalOpen(true);
      return makeAPICall({
        path: `/comment/create/${immediate.id}`,
        method: "POST",
        payload: data,
      })
        .then((res) => {
          // console.log(res);
          //modalLoading;
          //setModalLoading(false);
          setIsModalOpen(false);
          showInvestment();
        })
        .catch((err) => {
          //setModalLoading(false);
          setIsModalOpen(false);
          // console.log(err);
          message.error(err.message, 5);
        });
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getInvestments();
  }, []);

  function getInvestments() {
    setLoading(true);
    return makeAPICall({
      path: "/investor/list/investment/",
      method: "GET",
      params: null,
    })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  }

  const columnsComment = [
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: "400px",
    },
    {
      title: "Responded By",
      dataIndex: "responded_by",
      key: "responded_by",
    },

    {
      title: "Status",
      dataIndex: "is_closed",
      key: "is_closed",
      render: (is_closed) => {
        if (is_closed) {
          return (
            <span
              style={{
                backgroundColor: "#EA8745",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              Closed
            </span>
          );
        } else {
          return (
            <span
              style={{
                backgroundColor: "#14B941",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              Open
            </span>
          );
        }
      },
    },
  ];
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("L"),
    },
    {
      title: "Investment",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.investment?.name}</p>
      ),
    },
    {
      title: "Issuer",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>
          {row?.investment?.owner?.firstname} {row?.investment?.owner?.lastname}
        </p>
      ),
    },
    {
      title: "Volume",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>{row?.volume}</p>
      ),
    },
    {
      title: "Location",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <p style={{ textTransform: "capitalize" }}>
          {row?.investment?.location}
        </p>
      ),
    },
    {
      title: "Offer Starts",
      dataIndex: "id",
      key: "id",
      render: (id, row) => formatDate(row.investment.start_date),
    },
    {
      title: "Offer Ends",
      dataIndex: "id",
      key: "id",
      render: (id, row) => formatDate(row.investment.end_date),
    },
    {
      title: "Status",
      dataIndex: "is_closed",
      key: "is_closed",
      render: (is_closed) => {
        if (is_closed) {
          return (
            <span
              style={{
                backgroundColor: "#EA8745",
                borderRadius: "20px",
                padding: "8px",
                color: "white",
              }}
            >
              Closed
            </span>
          );
        } else {
          return (
            <span
              style={{
                backgroundColor: "#E8FFEE",
                borderRadius: "20px",
                padding: "8px",
                color: "#14B941",
              }}
            >
              Open
            </span>
          );
        }
      },
    },
  ];

  function getData(item) {
    // console.log(item, 'sjsjs')
    setCurrent(item.current);
    return makeAPICall({
      path: "/investor/list/investment/",
      method: "GET",
      params: { page: item.current },
    })
      .then((res) => {
        setLoading(false);
        setUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  }

  const hideView = () => {
    setInvestmentView(false);
  };

  const showInvestment = (record, rowIndex) => {
    console.log(record, rowIndex);
    setInvestmentView(true);
    setImmediate(record, rowIndex);
  };

  const onSearch = (value) => {
    setLoading(true);
    return makeAPICall({
      path: `/investor/list/investment/?s=${value}`,
      method: "GET",
      params: null,
    })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setUserTable(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  };

  return (
    <BackBone title="Investments">
      {investmentView ? (
        <div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div
                className="d-flex align-items-center"
                onClick={hideView}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={backbtn}
                  alt="go back"
                  style={{ marginRight: "10px" }}
                />
                <h1 className="back-text">Investment Details</h1>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-3 p-4">
            <div className="row">
              <div className="col-md-3">
                <Button
                  type="button"
                  onClick={showModal}
                  style={{
                    backgroundColor: "transparent",
                    color: "#ED8428",
                    border: "1px solid #ed8428",
                  }}
                >
                  <img
                    src={editIcon}
                    alt="go back"
                    style={{ marginRight: "10px", width: "15px" }}
                  />{" "}
                  Add comment
                </Button>
              </div>
              <div className="col-md-6 major-invest">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h1 style={{ textTransform: "capitalize" }}>
                      <span className="orange">
                        {immediate?.investor?.firstname},
                      </span>{" "}
                      {immediate?.investor?.lastname}
                    </h1>
                  </div>
                  <div>
                    <h2>{immediate?.investor?.email}</h2>
                    <p>Email</p>
                  </div>
                  <div>
                    <h2>{immediate?.investor?.phone}</h2>
                    <p>Phone Number</p>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h2 style={{ textTransform: "capitalize" }}>
                      {immediate?.investment?.room?.name}
                    </h2>
                    <p>Business Interest</p>
                  </div>
                  <div>
                    <h2>{immediate?.investment?.risk?.risk}</h2>
                    <p>Investment Appetite</p>
                  </div>
                  <div>
                    <h3 className={immediate?.is_closed ? "orange" : "green"}>
                      {immediate?.is_closed ? "Inactive" : "Active"}
                    </h3>
                    <p>Status</p>
                  </div>
                </div>
                <br />
                <br />
                <div className="d-flex align-items-center justify-content-around">
                  <div className="d-flex align-items-center">
                    <img
                      src={ticket}
                      alt="go back"
                      style={{
                        marginRight: "10px",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                    <span className="play-dis">Offer Discount Promo</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={disconnect}
                      alt="go back"
                      style={{ marginRight: "10px", width: "15px" }}
                    />
                    <span className="play-dis" style={{ color: "#CC2E2E" }}>
                      Deactivate Investor
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="investor-invest-bg p-4">
                  <p className="mb-3">Total Investment Value</p>
                  <h1 className="mb-5">₦ {formatMoney(immediate?.amount)}</h1>
                  <p className="mb-3">Investment Count</p>
                  <h2>{immediate?.investor?.totalinvestment}</h2>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                {immediate?.comment?.length > 0 ? (
                  <Table
                    columns={columnsComment}
                    dataSource={immediate?.comment}
                    rowKey="id"
                    scroll={{ x: "max-content" }}
                    pagination={false}
                  />
                ) : (
                  "No Comments"
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="table-records">
              <div className="d-flex justify-content-between mb-2">
                <h1>All Investments</h1>
                <div>
                  {/* <Input
                    size="large"
                    placeholder="Search Investments"
                    prefix={<SearchIcon />}
                  /> */}

                  <Search
                    placeholder="Search Investments"
                    onSearch={onSearch}
                    size="large"
                  />
                </div>
              </div>
              {loading ? (
                "Loading..."
              ) : (
                <Table
                  columns={columns}
                  dataSource={userTable?.results}
                  rowKey="id"
                  pagination={{
                    showSizeChanger: true,
                    total: userTable?.count,
                    current: current,
                  }}
                  onChange={(pagination) => {
                    getData(pagination);
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        showInvestment(record, rowIndex);
                      }, // click row
                    };
                  }}
                  scroll={{ x: "max-content" }}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        title="Add Comment"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="investment-form-fields">
          <h2>Add a comment</h2>
          <TextArea
            onChange={(e) => setCommentInput(e.target.value)}
            value={commentInput}
            name="description"
            rows="7"
            placeholder="A short paragraph describing the details of this investment."
          />
        </div>
      </Modal>
    </BackBone>
  );
}
