import React, { useState } from "react";
import InputField from "../../../components/InputField";
import ReactPhoneInput from "react-phone-input-2";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";

export default function First({ onClick }) {
  const [phoneState, setPhoneState] = useState("");
  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    referral: "",
  });

  function handleOnChangePhoneInput(value) {
    setPhoneState(value);
  }

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <div className="first-signup">
      <h1 className="mb-4">
        Tell us a bit about <span className="orange">yourself</span>
      </h1>
      <div>
        <h2 className="mb-1">First Name</h2>
        <InputField
          type="text"
          value={inputValues.first_name}
          onChange={handleChangeInput}
          name="first_name"
          placeholder="Femi"
        />
      </div>
      <div>
        <h2 className="mb-1">Last Name</h2>
        <InputField
          type="text"
          value={inputValues.last_name}
          onChange={handleChangeInput}
          name="last_name"
          placeholder="Adeyemi"
        />
      </div>
      <div>
        <h2 className="mb-1">Email</h2>
        <InputField
          type="email"
          value={inputValues.email}
          onChange={handleChangeInput}
          name="email"
          placeholder="example@mail.com"
        />
      </div>
      <div>
        <h2 className="mb-1">Phone number</h2>
        <ReactPhoneInput
          inputProps={{
            name: "phoneNumber",
            required: true,
            className: "formInput",
          }}
          value={phoneState}
          onChange={handleOnChangePhoneInput}
          country="ng"
          placeholder="080212345678"
          autoFormat={false}
          enableClickOutside={true}
        />
      </div>
      <div>
        <h2 className="mb-1">Address</h2>
        <InputField
          type="address"
          value={inputValues.address}
          onChange={handleChangeInput}
          name="address"
          placeholder="No 5 Ogunlana Drive, Surulere, Lagos"
        />
      </div>
      <div>
        <h2 className="mb-1">Referral Code</h2>
        <InputField
          type="text"
          value={inputValues.referral}
          onChange={handleChangeInput}
          name="referral"
          placeholder="optional"
        />
      </div>
      <Button
        type="button"
        onClick={() => onClick(inputValues, phoneState)}
        disabled={
          !(
            inputValues.first_name &&
            inputValues.last_name &&
            inputValues.email &&
            inputValues.address &&
            phoneState
          )
        }
        style={{ width: "100%", borderRadius: "10px" }}
      >
        Next - Investment information
      </Button>
      <div className="text-center mt-3">
        <p>
          Already have an account?{" "}
          <Link to="/">
            <span className="orange">Log in</span>
          </Link>{" "}
        </p>
      </div>
    </div>
  );
}
