import React, { useState, useEffect } from "react";
import backbtn from "../../../assets/img/Back.svg";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { DatePicker, Switch } from "antd";
import placeholder from "../../../assets/img/tied.png";
import makeAPICall from "../../../utils/apiUtils";
import { useSelector, useDispatch } from "react-redux";
import {
  getInvestmentSelector,
  InvestmentUser,
  revertAll,
} from "../../../services/slices/createInvestment";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

export default function UploadInvestment({ close }) {
  const dispatch = useDispatch();
  // use the hook and selector
  const { loading, newInvestment } = useSelector(getInvestmentSelector);
  const [inputValues, setInputValues] = useState({
    investment_name: "",
    investment_price: "",
    investment_offer_price: "",
    investment_unit_price: "",
    investment_spot_price: "",
    investment_video: "",
    investment_volume: "",
    investment_location: "",
    investment_description: "",
    investment_features: "",
    investment_return: "",
    investment_annual: "",
    investment_project_raise: "",
    investment_project_cost: "",
    investment_minimum_allotment: "",
    investment_maximum_allotment: "",
    investment_milestone: "",
    outright_purchase_amount: "",
  });
  const [period, setPeriod] = useState();
  const [category, setCategory] = useState();
  const [investPeriod, setInvestPeriod] = useState("");
  const [investCategory, setInvestCategory] = useState("");
  const [dealTypeMain, setDealTypeMain] = useState("");
  const [deal, setDeal] = useState();
  const [currency, setCurrency] = useState();
  const [currencyTypeMain, setCurrencyTypeMain] = useState("");
  const [periodicType, setPeriodicType] = useState("");
  const [image, setImage] = useState();
  const [multipleImage, setMultipleImage] = useState([]);
  const [risk, setRisk] = useState();
  const [investRisk, setInvestRisk] = useState("");
  const [offerPeriod, setOfferPeriod] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  //const [onlyReturns, setOnlyReturns] = useState();
  //const [offPlan, setOffPlan] = useState();
  //const [outrightPurchase, setOutrightPurchase] = useState();
  const [toggleOnlyReturns, setToggleOnlyReturns] = useState(false);
  const [toggleOffPlan, setToggleOffPlan] = useState(false);
  const [toggleOutrightPurchase, setToggleOutrightPurchase] = useState(false);

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const handlePeriodChange = (e) => {
    setInvestPeriod(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setInvestCategory(e.target.value);
  };

  const handleDealType = (e) => {
    setDealTypeMain(e.target.value);
  };

  const handleCurrencyType = (e) => {
    setCurrencyTypeMain(e.target.value);
  };

  const handlePeriodicType = (e) => {
    setPeriodicType(e.target.value);
  };

  const onChange = (date, dateString) => {
    // console.log(dateString);
    setOfferPeriod(moment(dateString));
  };

  const onChangeStart = (date, dateString) => {
    // console.log(dateString);
    setStartDate(moment(dateString));
  };

  const onChangeEnd = (date, dateString) => {
    // console.log(dateString);
    setEndDate(moment(dateString));
  };

  const togglerOnlyReturns = () => {
    toggleOnlyReturns
      ? setToggleOnlyReturns(false)
      : setToggleOnlyReturns(true);
  };

  const togglerOffPlan = () => {
    toggleOffPlan ? setToggleOffPlan(false) : setToggleOffPlan(true);
  };

  const togglerOutrightPurchase = () => {
    toggleOutrightPurchase
      ? setToggleOutrightPurchase(false)
      : setToggleOutrightPurchase(true);
  };

  useEffect(() => {
    const investmentCategory = () => {
      return makeAPICall({
        path: "/investment/room/all/",
        method: "GET",
      })
        .then((res) => {
          // console.log(res, "period");
          setCategory(res.results);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const investmentAppetite = () => {
      return makeAPICall({
        path: "/investor/risk/all",
        method: "GET",
      })
        .then((res) => {
          // console.log(res, "risk");
          setRisk(res.results);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const investmentPeriod = () => {
      return makeAPICall({
        path: "/investor/period/all",
        method: "GET",
      })
        .then((res) => {
          // console.log(res, "period");
          setPeriod(res.results);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const currencyType = () => {
      return makeAPICall({
        path: "/investment/currency/",
        method: "GET",
      })
        .then((res) => {
          // console.log(res, "currency");
          setCurrency(res.results);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const dealType = () => {
      return makeAPICall({
        path: "/investment/dealtype/",
        method: "GET",
      })
        .then((res) => {
          // console.log(res, "deal type");
          setDeal(res.results);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    currencyType();
    dealType();
    investmentPeriod();
    investmentAppetite();
    investmentCategory();
  }, []);

  useEffect(() => {
    if (
      newInvestment &&
      Object.keys(newInvestment).length === 0 &&
      Object.getPrototypeOf(newInvestment) === Object.prototype
    ) {
      console.log("yes");
    } else {
      close();
      dispatch(revertAll());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newInvestment]);

  const handleChange = (e) => {
    const uploadFile = e.target.files[0];
    if (uploadFile) {
      const fileType = uploadFile["type"];
      const validImageTypes = ["image/png", "image/jpeg"];
      if (validImageTypes.includes(fileType)) {
        setImage(uploadFile);
      } else {
        console.log("Please select an image to upload");
      }
    }
  };

  const handleChangeMultiple = (e) => {
    const files = [...multipleImage];
    files.push(...e.target.files);
    setMultipleImage(files);
  };

  const handleRiskChange = (e) => {
    setInvestRisk(e.target.value);
  };

  const newInvestmentButton = () => {
    const formData = new FormData();
    formData.append("gallery", image);
    for (let i = 0; i < multipleImage.length; i++) {
      formData.append(`galleries_${i}`, multipleImage[i]);
    }
    formData.append("name", inputValues.investment_name);
    formData.append("description", inputValues.investment_description);
    formData.append("features", inputValues.investment_features);
    formData.append("room", investCategory);
    formData.append("period", investPeriod);
    formData.append("roi", inputValues.investment_return);
    formData.append("annualized", inputValues.investment_annual);
    formData.append("risk", investRisk);
    formData.append("amount", inputValues.investment_price);
    formData.append("location", inputValues.investment_location);
    formData.append("is_verified", "True");
    formData.append("video", inputValues.investment_video);
    formData.append("volume", inputValues.investment_volume);
    formData.append("dealtype", dealTypeMain);
    formData.append("currency", currencyTypeMain);
    formData.append("offer_price", inputValues.investment_offer_price);
    formData.append("spot_price", inputValues.investment_spot_price);
    formData.append("unit_price", inputValues.investment_unit_price);
    formData.append("start_date", startDate.format("YYYY-MM-DD"));
    formData.append("end_date", endDate.format("YYYY-MM-DD"));
    formData.append("offer_period", offerPeriod.format("YYYY-MM-DD"));
    formData.append("project_cost", inputValues.investment_project_cost);
    formData.append("project_raise", inputValues.investment_project_raise);
    formData.append("milestone", inputValues.investment_milestone);
    formData.append(
      "minimum_allotment",
      inputValues.investment_minimum_allotment
    );
    formData.append(
      "maximum_allotment",
      inputValues.investment_maximum_allotment
    );
    formData.append("periodic_payment", periodicType);
    // for (var d of formData.entries()) {
    //     console.log(d);
    // }
    formData.append(
      "outright_purchase_amount",
      inputValues.outright_purchase_amount
    );
    formData.append("off_plan", toggleOffPlan);
    formData.append("only_returns", toggleOnlyReturns);
    formData.append("outright_purchase", toggleOutrightPurchase);
    dispatch(InvestmentUser(formData));
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-md-12">
          <div
            className="d-flex align-items-center"
            onClick={close}
            style={{ cursor: "pointer" }}
          >
            <img src={backbtn} alt="go back" style={{ marginRight: "10px" }} />
            <h1 className="back-text">Upload Investment</h1>
          </div>
        </div>
      </div>
      <div className="bg-white investment-form-fields rounded-3 p-4">
        <div className="row mt-5">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Investment Name</h2>
              <InputField
                type="text"
                value={inputValues.investment_name}
                onChange={handleChangeInput}
                name="investment_name"
                placeholder="Give the investment a name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Investment Value</h2>
              <InputField
                onChange={handleChangeInput}
                value={inputValues.investment_price}
                name="investment_price"
                type="number"
                placeholder="0,00"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Category</h2>
              <select
                onChange={handleCategoryChange}
                className="select-area"
                name="investCategory"
                value={investCategory}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                {category?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">Location of the Investment</h2>
              <InputField
                type="text"
                value={inputValues.investment_location}
                onChange={handleChangeInput}
                name="investment_location"
                placeholder="Enter investment location"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Associated Return{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Return on Investment (ROI)
                  </span>
                </div>
              </h2>
              <InputField
                value={inputValues.investment_return}
                onChange={handleChangeInput}
                name="investment_return"
                type="text"
                placeholder="Enter returns e.g. 10%"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">
                Tenor{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Investment period
                  </span>
                </div>
              </h2>
              <select
                onChange={handlePeriodChange}
                className="select-area"
                name="investPeriod"
                value={investPeriod}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                {period?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.period}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Description</h2>
              <InputField
                type="text"
                value={inputValues.investment_description}
                onChange={handleChangeInput}
                name="investment_description"
                placeholder="Describe the investment"
              />
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Feature List
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Enter these Number of rooms, land size, Title Approval State
                    - completed, Construction Approval State - in progress,
                    Number of floors - 5, Developer - (Chimauche Njoku),
                    Architect - (Emmanuel Chilaka), QS - (Jaja Nonso), EA -
                    (Demo Usman), ME - (Sample Bukky), Registration Stratis -
                    (Completed)
                  </span>
                </div>
              </h2>
              <TextArea
                value={inputValues.investment_features}
                onChange={handleChangeInput}
                name="investment_features"
                placeholder="Add features of the investment separated with comma, eg Number of floors - 5, Number of room - 15, Developer: Sanchez Consultant"
              />
            </div>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Only Returns </h2>
              <Switch onClick={togglerOnlyReturns} />
              {toggleOnlyReturns ? (
                <span>Only Returns</span>
              ) : (
                <span>Choose Only Returns</span>
              )}
            </div>
            <br />
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Off Plan{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    The cost of a standalone unit or flat in a project if
                    payment is in installment
                  </span>
                </div>
              </h2>
              <Switch onClick={togglerOffPlan} />
              {toggleOffPlan ? (
                <span>Off Plan</span>
              ) : (
                <span>Choose Off Plan</span>
              )}
            </div>
            <br />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">
                Outright Purchase{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Outright purchase price of a unit
                  </span>
                </div>
              </h2>
              <Switch onClick={togglerOutrightPurchase} />
              {toggleOutrightPurchase ? (
                <span>Outright Purchase</span>
              ) : (
                <span>Choose Outright Purchase</span>
              )}
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Outright Purchase Amount{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Outright purchase price of a unit
                  </span>
                </div>
              </h2>
              <InputField
                type="number"
                value={inputValues.outright_purchase_amount}
                onChange={handleChangeInput}
                name="outright_purchase_amount"
                placeholder="100000000"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Investment Appetite</h2>
              <select
                onChange={handleRiskChange}
                className="select-area"
                name="investRisk"
                value={investRisk}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                {risk?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.risk}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Annualized</h2>
              <InputField
                value={inputValues.investment_annual}
                onChange={handleChangeInput}
                name="investment_annual"
                type="text"
                placeholder="Enter annual investment e.g. 10%"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Investment Video</h2>
              <InputField
                type="text"
                value={inputValues.investment_video}
                onChange={handleChangeInput}
                name="investment_video"
                placeholder="e.g https://www.youtube.com/watch?v=c_OwSqXjkg4"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Investment Volume(Number of Shares)</h2>
              <InputField
                type="number"
                value={inputValues.investment_volume}
                onChange={handleChangeInput}
                name="investment_volume"
                placeholder="e.g. 216"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">
                Investment DealType{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Where it is an equity or debt or any other instrument
                  </span>
                </div>
              </h2>
              <select
                onChange={handleDealType}
                className="select-area"
                name="dealTypeMain"
                value={dealTypeMain}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                {deal?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">Investment Currency</h2>
              <select
                onChange={handleCurrencyType}
                className="select-area"
                name="currencyTypeMain"
                value={currencyTypeMain}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                {currency?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Investment Offer Price{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    The bid price for a property tabled by an issuer
                  </span>
                </div>
              </h2>
              <InputField
                onChange={handleChangeInput}
                value={inputValues.investment_offer_price}
                name="investment_offer_price"
                type="number"
                placeholder="0,00"
              />
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Investment Spot Price{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    The spot price for a property tabled by an issuer
                  </span>
                </div>
              </h2>
              <InputField
                onChange={handleChangeInput}
                value={inputValues.investment_spot_price}
                name="investment_spot_price"
                type="number"
                placeholder="0,00"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Investment Unit Price{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    The unit price for a property tabled by an issuer
                  </span>
                </div>
              </h2>
              <InputField
                onChange={handleChangeInput}
                value={inputValues.investment_unit_price}
                name="investment_unit_price"
                type="number"
                placeholder="0,00"
              />
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Investment Offer Period{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Period for which the offer is open for subscription
                  </span>
                </div>
              </h2>
              <DatePicker value={offerPeriod} onChange={onChange} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Investment Start Date{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    When the project for which the funding is needed starts
                  </span>
                </div>
              </h2>
              <DatePicker value={startDate} onChange={onChangeStart} />
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">Investment End Date</h2>
              <DatePicker value={endDate} onChange={onChangeEnd} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Investment Project Cost
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Total cost of a project or development which may not be
                    equal to the total amount to be raised
                  </span>
                </div>
              </h2>
              <InputField
                onChange={handleChangeInput}
                value={inputValues.investment_project_cost}
                name="investment_project_cost"
                type="number"
                placeholder="0,00"
              />
            </div>
          </div>
          <div className="col-md-6 different-input">
            <div>
              <h2 className="mb-1">
                Investment Project Raise{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Maximum amount planned to be raised
                  </span>
                </div>
              </h2>
              <InputField
                onChange={handleChangeInput}
                value={inputValues.investment_project_raise}
                name="investment_project_raise"
                type="number"
                placeholder="0,00"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">
                Investment Minimum Allotment{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Minimum amount that an investor can subscribe to
                  </span>
                </div>
              </h2>
              <InputField
                onChange={handleChangeInput}
                value={inputValues.investment_minimum_allotment}
                name="investment_minimum_allotment"
                type="number"
                placeholder="e.g. 500000"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">
                Investment Maximum Allotment{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Maximum amount that an investor can subscribe to
                  </span>
                </div>
              </h2>
              <InputField
                onChange={handleChangeInput}
                value={inputValues.investment_maximum_allotment}
                name="investment_maximum_allotment"
                type="number"
                placeholder="e.g. 800000"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">
                Investment MileStone{" "}
                <div className="hover-text btn btn-primary btn-sm rounded-circle">
                  ?
                  <span className="tooltip-text" id="right">
                    Amount to be raised before starting the project
                  </span>
                </div>
              </h2>
              <InputField
                onChange={handleChangeInput}
                value={inputValues.investment_milestone}
                name="investment_milestone"
                type="number"
                placeholder="e.g. 500000"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Investment Periodic Payment</h2>
              <select
                onChange={handlePeriodicType}
                className="select-area"
                name="periodicType"
                value={periodicType}
              >
                <option disabled={true} value="">
                  --Choose an option--
                </option>
                <option value="1">Every 12 Months</option>
                <option value="2">Every 6 Months</option>
                <option value="3">Every 4 Months</option>
                <option value="4">Every 3 Months</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Featured Image</h2>
              <div className="img-upload p-2">
                <label htmlFor="upload-button" className="label-profile">
                  <img src={placeholder} alt="take" />
                  {image ? image.name : "Upload Image"}
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h2 className="mb-1">Other Images</h2>
              <div className="img-upload p-2">
                <label htmlFor="upload-button-two" className="label-profile">
                  <img src={placeholder} alt="take" />{" "}
                  {multipleImage.length > 0
                    ? multipleImage.map((x) => x.name).join(", ")
                    : "Upload max of four images"}
                </label>
                <input
                  type="file"
                  id="upload-button-two"
                  style={{ display: "none" }}
                  multiple
                  onChange={handleChangeMultiple}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 text-center">
            <Button
              type="button"
              onClick={newInvestmentButton}
              disabled={
                !(
                  inputValues.investment_name &&
                  inputValues.investment_price &&
                  inputValues.investment_return &&
                  inputValues.investment_location &&
                  inputValues.investment_features &&
                  inputValues.investment_description &&
                  inputValues.investment_offer_price &&
                  inputValues.investment_spot_price &&
                  inputValues.investment_unit_price &&
                  inputValues.investment_video &&
                  inputValues.investment_volume &&
                  inputValues.investment_project_cost &&
                  inputValues.investment_project_raise &&
                  inputValues.investment_milestone &&
                  inputValues.investment_minimum_allotment &&
                  inputValues.investment_maximum_allotment &&
                  startDate &&
                  endDate &&
                  offerPeriod &&
                  currencyTypeMain &&
                  dealTypeMain &&
                  periodicType &&
                  image &&
                  investPeriod &&
                  investCategory
                )
              }
            >
              {loading ? "Loading..." : "Upload Investment"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
