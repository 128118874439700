import "./step.css";
import logo from "../../assets/img/logo.png";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

export default function Pending() {
  const navigate = useNavigate();

  const gotoLogin = () => {
    navigate("/");
    window.localStorage.removeItem("persist:counter");
  };

  return (
    <section className="step-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row bg-white rounded-3">
              <div className="col-md-12">
                <div className="bg-white p-5">
                  <div className="text-center">
                    <img
                      src={logo}
                      alt="Yield room Logo"
                      className="logo mb-3"
                    />
                    <br />
                    <h1 className="congrats mb-3">Account awaiting review </h1>
                    <p className="congrats-details mb-5">
                      Your account will be approved within 24 working hours,
                      please look up via mail for our response
                    </p>
                    <Button type="button" onClick={gotoLogin}>
                      Proceed to Login
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
