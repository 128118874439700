import React, { useState } from "react";
import logo from "../../../assets/img/logo.png";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import InputField from "../../../components/InputField";
import { toast } from "react-toastify";
import makeAPICall from "../../../utils/apiUtils";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    email: "",
  });
  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }
  //const dispatch = useDispatch();
  // use the hook and selector
  //const { loading } = useSelector(getLoginSelector);

  const handleSubmit = () => {
    setIsLoading(true);
    const data = {
      email: inputValues.email,
      callbackUrl: "yieldroom.africa",
    };
    return makeAPICall({
      path: "/auth/request-reset-email/",
      method: "POST",
      payload: data,
    })
      .then((res) => {
        setIsLoading(false);
        toast("Please check your email for a password reset link", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <section className="step-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row remove-p bg-white">
              <div className="col-md-4">
                <div className="step-div p-5">
                  <img src={logo} alt="Yield room Logo" className="logo" />
                  <div>
                    <h1 className="login-welcome mt-5">Reset Password</h1>
                    <p className="login-welcome-details">
                      Enter your email address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="content-div bg-white p-5">
                  <div className="first-signup">
                    <h1 className="mb-4">Reset your password</h1>
                    <div>
                      <h2 className="mb-1">Email</h2>
                      <InputField
                        type="email"
                        value={inputValues.email}
                        onChange={handleChangeInput}
                        name="email"
                        placeholder="example@mail.com"
                      />
                    </div>

                    <Button
                      type="button"
                      onClick={handleSubmit}
                      disabled={!inputValues.email}
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        marginTop: "20px",
                      }}
                    >
                      {isLoading ? "Loading..." : "Reset"}
                    </Button>
                    <div className="text-center mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <Link to="/">
                              <span className="orange">Sign in</span>
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
