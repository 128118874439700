import React, { useState } from "react";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { DatePicker } from "antd";
import moment from "moment";
import placeholder from "../../../assets/img/tied.png";

export default function Second({ onClick }) {
  const [inputValues, setInputValues] = useState({
    linkedIn: "",
    nin: "",
  });
  const [dob, setDob] = useState();
  const [identity, setIdentity] = useState();

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const onChangeDob = (date, dateString) => {
    // console.log(dateString);
    setDob(moment(dateString));
  };

  const handleChangeIdentity = (e) => {
    const uploadFile = e.target.files[0];
    if (uploadFile) {
      const fileType = uploadFile["type"];
      const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (validImageTypes.includes(fileType)) {
        setIdentity(uploadFile);
      } else {
        console.log("Please select an image to upload");
      }
    }
  };

  return (
    <div className="first-signup">
      <h1 className="mb-4">
        Tell us a bit about <span className="orange">yourself</span>
      </h1>
      <div>
        <h2 className="mb-1">LinkedIn Profile</h2>
        <InputField
          type="text"
          value={inputValues.linkedIn}
          onChange={handleChangeInput}
          name="linkedIn"
          placeholder="https://www.linkedin.com/in/username"
        />
      </div>
      <br />
      <div>
        <h2 className="mb-1">Date of birth</h2>
        <DatePicker value={dob} onChange={onChangeDob} />
      </div>
      <br />
      <div>
        <h2 className="mb-1">National Identity Number (NIN)</h2>
        <InputField
          type="text"
          value={inputValues.nin}
          onChange={handleChangeInput}
          name="nin"
          placeholder="1234567890"
        />
      </div>
      <div>
        <h2 className="mb-1">Upload Identity (Passpord, driving license)</h2>
        <label htmlFor="upload-button" className="label-profile">
          <img src={placeholder} alt="take" />
          {identity ? identity.name : "Upload Image"}
        </label>
        <input
          type="file"
          id="upload-button"
          style={{ display: "none" }}
          onChange={handleChangeIdentity}
        />
      </div>
      <Button
        type="button"
        onClick={() =>
          onClick(inputValues.linkedIn, inputValues.nin, dob, identity)
        }
        disabled={!(inputValues.nin && dob && identity)}
        style={{ width: "100%", borderRadius: "10px" }}
      >
        Next - Create Password
      </Button>
      <div className="text-center mt-3">
        <p>
          Already have an account? <span className="orange">Log in</span>
        </p>
      </div>
    </div>
  );
}
