import React, { useState, useEffect } from "react";
import AdminBackBone from "../../../components/AdminBackBone";
import placeholder from "../../../assets/img/tied.png";
import Button from "../../../components/Button";
import { Table, message, Drawer, Input, DatePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";

// import { investments } from "./table";
import moment from "moment";
import UploadInvestment from "./uploadInvestment";
import { useSelector, useDispatch } from "react-redux";
import {
  GetSponsorUser,
  getCurrentSponsorSelector,
} from "../../../services/slices/getSponsor";
import makeAPICall from "../../../utils/apiUtils";
//import makeAPICallForm from "../../../utils/apiUtilsForm";
import InputField from "../../../components/InputField";
import { SponsorUser } from "../../../services/slices/createSponsor";
//import { InvestorUser } from "../../../services/slices/createInvestor";
const baseURL = process.env.REACT_APP_API_ENDPOINT;

export default function SponsorDashboard() {
  const dispatch = useDispatch();
  // use the hook and selector
  const { loading, sponsor } = useSelector(getCurrentSponsorSelector);
  const [views, setViews] = useState(false);
  //const [viewsInvestment, setViewsInvestment] = useState(false);
  const [current, setCurrent] = useState(1);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });
  const [loadingEdit, setLoadingEdit] = useState(false);
  //const [isLoadingClose, setIsLoadingClose] = useState({
  //  loading: false,
  //  key: 0,
  //});
  //const [open, setOpen] = useState(false);
  const [openSponsor, setOpenSponsor] = useState(false);
  //const [openInvestor, setOpenInvestor] = useState(false);

  const [inputValues, setInputValues] = useState({
    name: "",
    address: "",
    phone: "",
    nin: "",
    identity_url: "",
  });

  const [dob, setDob] = useState();
  const [identity, setIdentity] = useState();
  const [immediate, setImmediate] = useState();

  //const [investmentView, setInvestmentView] = useState(false);
  //const [anotherLoading, setAnotherLoading] = useState(false);
  //const [modalLoading, setModalLoading] = useState(false);
  //const [currentTwo, setCurrentTwo] = useState(1);
  //const [isModalOpen, setIsModalOpen] = useState(false);
  //const [singleInvestment, setSingleInvestment] = useState();

  const onChangeDob = (date, dateString) => {
    // console.log(dateString);
    setDob(moment(dateString));
  };

  const handleChangeIdentity = (e) => {
    const uploadFile = e.target.files[0];
    if (uploadFile) {
      const fileType = uploadFile["type"];
      const validImageTypes = ["image/png", "image/jpeg"];
      if (validImageTypes.includes(fileType)) {
        setIdentity(uploadFile);
      } else {
        console.log("Please select an image to upload");
      }
    }
  };
  const showDrawerSponsor = (row) => {
    setInputValues({
      name: row?.name,
      nin: row?.nin,
      phone: row?.phone,
      dob: row?.dob,
      address: row?.address,
      identity: row?.identity,
      identity_url: row?.identity_url,
    });
    setImmediate(row);
    setOpenSponsor(true);
  };

  const onClose = () => {
    setOpenSponsor(false);
  };

  const showView = () => {
    setViews(true);
  };

  const hideView = () => {
    setViews(false);
  };

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const approve = (id, verified) => {
    if (verified) {
      const data = {
        is_verified: "False",
      };
      setIsLoading({ loading: true, key: id });
      return makeAPICall({
        path: `/investment/verify/sponsor/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoading({ loading: false, key: 0 });
          dispatch(GetSponsorUser({}));
        })
        .catch((err) => {
          setIsLoading({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    } else {
      const data = {
        is_verified: "True",
      };
      setIsLoading({ loading: true, key: id });
      return makeAPICall({
        path: `/investment/verify/sponsor/${id}`,
        payload: data,
        method: "PATCH",
      })
        .then((res) => {
          setIsLoading({ loading: false, key: 0 });
          dispatch(GetSponsorUser({}));
        })
        .catch((err) => {
          setIsLoading({ loading: false, key: 0 });
          message.error(err.message, 5);
        });
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("L"),
    },
    {
      title: "Sponsor Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "NIN",
      dataIndex: "nin",
      key: "nin",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Identity",
      dataIndex: "identity_url",
      key: "identity_url",
      render: (id, row) => (
        <a href={`${baseURL}${row?.identity_url}`}>View Identity</a>
      ),
    },
    {
      title: "Action",
      dataIndex: "is_verified",
      key: "is_verified",
      width: "100px",
      render: (is_verified, row) => {
        if (is_verified) {
          return (
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                onClick={() => showDrawerSponsor(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                View Sponsor
              </Button>
              <Button
                type="button"
                onClick={() => approve(row.id, is_verified)}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Disapprove"}
              </Button>
            </div>
          );
        } else {
          return (
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                onClick={() => showDrawerSponsor(row)}
                style={{ backgroundColor: "transparent", color: "#ED8428" }}
              >
                View Sponsor
              </Button>
              <Button
                type="button"
                onClick={() => approve(row.id, is_verified)}
              >
                {isLoading.loading && isLoading.key === row.id
                  ? "Loading..."
                  : "Approve"}
              </Button>
            </div>
          );
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(GetSponsorUser({}));
  }, [dispatch]);

  function getData(item) {
    // console.log(item, 'sjsjs')
    setCurrent(item.current);
    dispatch(
      GetSponsorUser({
        params: { page: item.current },
      })
    );
  }

  const newSponsorButton = () => {
    console.log(identity);
    const formData = new FormData();
    const investmentId = immediate.id;
    formData.append("name", inputValues.name);
    formData.append("phone", inputValues.phone);
    formData.append("nin", inputValues.nin);
    formData.append("dob", dob.format("YYYY-MM-DD"));
    formData.append("address", inputValues.address);
    formData.append("identity", identity);
    setLoadingEdit(true);

    dispatch(SponsorUser(formData, investmentId));
  };

  return (
    <AdminBackBone title="Dashboard" onClick={showView}>
      {views ? (
        <UploadInvestment close={hideView} />
      ) : (
        <div>
          {" "}
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="table-records">
                <div className="d-flex justify-content-between mb-2">
                  <h1>Sponsors</h1>
                  {/* <p>See more</p> */}
                </div>
                {loading ? (
                  "Loading..."
                ) : (
                  <Table
                    columns={columns}
                    dataSource={sponsor?.results}
                    rowKey="id"
                    pagination={{
                      showSizeChanger: true,
                      total: sponsor?.count,
                      current: current,
                    }}
                    onChange={(pagination) => {
                      getData(pagination);
                    }}
                    scroll={{ x: "max-content" }}
                  />
                )}
              </div>
            </div>
          </div>{" "}
        </div>
      )}
      <Drawer
        title="Add Sponsor"
        placement="right"
        onClose={onClose}
        open={openSponsor}
      >
        <div className="row investment-form-fields">
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">Sponsor Full Name</h2>
              <InputField
                type="text"
                value={inputValues.name}
                onChange={handleChangeInput}
                name="name"
                placeholder="Give the Sponsor name"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Date of birth</h2>
              <DatePicker value={dob} onChange={onChangeDob} />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">National Indentification Number (NIN)</h2>
              <Input
                onChange={handleChangeInput}
                value={inputValues.nin}
                name="nin"
                type="text"
                placeholder="Enter your National Indentification Number (NIN)"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Phone Number</h2>
              <InputField
                type="text"
                value={inputValues.phone}
                onChange={handleChangeInput}
                name="phone"
                placeholder="Enter phone number"
              />
            </div>
          </div>
          <div className="col-md-12 different-input">
            <div>
              <h2 className="mb-1">Address</h2>
              <TextArea
                value={inputValues.address}
                onChange={handleChangeInput}
                name="address"
                placeholder="Enter sponsor address"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <h2 className="mb-1">
                Upload Identity (Passpord, driving license)
              </h2>
              <label htmlFor="upload-button" className="label-profile">
                <img src={placeholder} alt="take" />
                {identity ? identity.name : "Upload Image"}
              </label>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChangeIdentity}
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <Button
              type="button"
              onClick={newSponsorButton}
              disabled={
                !(
                  inputValues.name &&
                  inputValues.nin &&
                  inputValues.phone &&
                  inputValues.address &&
                  dob &&
                  identity
                )
              }
            >
              {loadingEdit ? "Loading..." : "Add Sponsor"}
            </Button>
          </div>
        </div>
      </Drawer>
    </AdminBackBone>
  );
}
